import { useState, useEffect } from "react";

import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCardMenu";
import MiniLoadingCard from "examples/Cards/Loading";

// React icons
import { IoImagesOutline } from "react-icons/io5";
import { IoMenuOutline } from "react-icons/io5";
import { IoAddCircleOutline } from "react-icons/io5";
import { IoPencilOutline } from "react-icons/io5";
import { IoTrashBin } from "react-icons/io5";

// Service Axios
import api from "services/api";

function MiniCardBanner({ currentIndex, countAllData, execAdd, execEdit, execDelete }) {
  return (
    <MiniStatisticsCard
      // menuController={{
      //   icon: { color: "white", component: <IoMenuOutline size="32px" color="black" /> },
      // }}
      buttonAddImage={{
        icon: {
          color: "white",
          component: <IoAddCircleOutline size="32px" color="black" />,
          exec: () => {
            execAdd();
          },
        },
      }}
      buttonEditImage={{
        icon: {
          color: "white",
          component: <IoPencilOutline size="32px" color="black" />,
          exec: () => {
            execEdit();
          },
        },
      }}
      buttonDeleteImage={{
        icon: {
          color: "white",
          component: <IoTrashBin size="32px" color="black" />,
          exec: () => {
            execDelete();
          },
        },
      }}
      direction="left"
      title={{ text: "Total de Banner" }}
      count={`${currentIndex + 1} / ${countAllData}`}
      percentage={{
        color: countAllData === 0 ? "error" : "success",
        text: countAllData > 1 ? "Images disponiveis" : "Imagem",
      }}
      icon={{
        color: countAllData === 0 ? "error" : "success",
        component: <IoImagesOutline size="22px" color="white" />,
      }}
    />
  );
}

export default MiniCardBanner;
