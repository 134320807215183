// import * as React from "react";
import Modal from "@mui/material/Modal";

export default function InfoMarkerModal({ isOpen = false, onClose = () => {}, children }) {
  return (
    <div>
      <Modal keepMounted open={isOpen} onClose={onClose}>
        {children}
      </Modal>
    </div>
  );
}
