import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiProgress from "components/VuiProgress";
import VuiOpenLink from "components/VuiOpenLink";
import VuiLoading from "components/VuiLoading";
import VuiLabel from "components/VuiLabel";

import moment from "moment";
import api from "services/api";
import useWindowDimensions from "hooks/useWindowDimensions";

import VuiInfoItemLink from "components/VuiInfoItemLink";

import colors from "assets/theme/base/colors";

// Function randomInt(min, max)
// min and max included
function randomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function Links() {
  const { height, width } = useWindowDimensions();
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [refreshInterval, setRefreshInterval] = useState(60000);
  const [countOffLines, setCountOfflines] = useState(0);
  const [countOnLines, setCountOnlines] = useState(0);
  const [countAllUnits, setCountAllUnits] = useState(0);

  async function loadLinks() {
    setLoading(true);
    const res = await api.get("links");

    const data = [];
    res.data.map((x) => {
      if (x.enabled) {
        data.push({
          ...x,
          ip: x.gateway,
          status: x.laststatus,
          stage: x.hops,
          ttl: x.max,
          updatedAt: x.laststatus
            ? moment().format("DD/MM/YYYY HH:mm:ss")
            : moment(x.updatedAt).format("DD/MM/YYYY HH:mm:ss"),
          lastUpdatedAt: moment().format("DD/MM/YYYY HH:mm:ss"),
        });
      }
    });

    const offlineLinks = data.filter((x) => !x.status).length;
    const onlineLinks = data.filter((x) => x.status).length;
    let roundLink = randomInt(0, data.length - 1);

    if (offlineLinks > 0) {
      roundLink = 0;
    }

    setCountOfflines(offlineLinks);
    setCountOnlines(onlineLinks);
    setCountAllUnits(data.length);

    setRows(data[roundLink]);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  useEffect(() => {
    loadLinks();
  }, [false]);

  useEffect(() => {
    if (refreshInterval && refreshInterval > 0) {
      const interval = setInterval(loadLinks, refreshInterval);
      return () => clearInterval(interval);
    }
  }, [refreshInterval]);

  return (
    <>
      {loading ? (
        <VuiLoading />
      ) : (
        <div>
          <div
            style={{
              display: "grid",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              marginBottom: 20,
            }}
          >
            <VuiOpenLink
              title={`${countAllUnits} Links`}
              url="links"
              colorTitle="warning"
              fontWeight="bold"
              fontSize={24}
            />

            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
              {countOffLines > 0 ? (
                <>
                  <div style={{ textAlign: "right", color: colors.warning.main }}>
                    {countOffLines > 0 && countOffLines < countAllUnits
                      ? ((countOffLines / countAllUnits) * 100).toFixed(2)
                      : 100}
                    %
                  </div>
                  <div
                    style={{
                      fontWeight: "bold",
                      textAlign: "right",
                      color: colors.error.main,
                    }}
                  >
                    Offline
                  </div>
                </>
              ) : (
                <>
                  <div style={{ textAlign: "right", color: colors.success.focus }}>100%</div>
                  <div
                    style={{ fontWeight: "bold", textAlign: "right", color: colors.success.main }}
                  >
                    Online
                  </div>
                </>
              )}
            </div>
            <VuiProgress
              value={(countOffLines / countAllUnits) * 100}
              color="error"
              label={false}
            />
          </div>
          <VuiBox display={"flex"} justifyContent={"flex-end"}>
            {countOffLines > 0 ? (
              <>
                <NotificationsActiveIcon fontSize="large" color="error" />
                <VuiOpenLink
                  title={`${countOffLines} ${
                    countOffLines > 1 ? "unidades" : "unidade"
                  } Offine de ${countAllUnits} Ativas`}
                  colorTitle={"error"}
                  url="links?search=offline"
                />
              </>
            ) : null}
          </VuiBox>
          <VuiBox mb={2} display={"flex"} justifyContent={"flex-end"}>
            {countOnLines > 0 ? (
              <VuiOpenLink
                title={`${countOnLines} Unidades Online`}
                colorTitle={"success"}
                url="links?search=online"
              />
            ) : null}
          </VuiBox>
          <Box sx={{ flexGrow: 2 }}>
            <VuiInfoItemLink item={rows} index={0} />
          </Box>
        </div>
      )}
    </>
  );
}

export default Links;
