import { useState, useEffect } from "react";

import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import MiniLoadingCard from "examples/Cards/Loading";

// React icons
import { IoCallSharp } from "react-icons/io5";

// Service Axios
import api from "services/api";

function MiniCardClocks() {
  const [refreshInterval, setRefreshInterval] = useState(60000);
  const [loading, setLoading] = useState(true);
  const [countVoIPsOffLines, setCountVoIPsOfflines] = useState(0);
  const [countVoIPsOnLines, setCountVoIPsOnlines] = useState(0);
  const [countAllVoIPs, setCountAllVoIPs] = useState(0);

  async function loadInfoDashAdmin() {
    setLoading(true);
    const res = await api.get("clocktime");

    const data = res.data.filter((x) => x.enabled);
    const voipsTotal = data.length;
    const offlineLinks = data.filter((x) => !x.status).length;
    const onlineLinks = data.filter((x) => x.status).length;

    setCountAllVoIPs(voipsTotal);
    setCountVoIPsOfflines(offlineLinks);
    setCountVoIPsOnlines(onlineLinks.toFixed(voipsTotal === offlineLinks ? 0 : 2));

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  useEffect(() => {
    loadInfoDashAdmin();
  }, [false]);

  useEffect(() => {
    if (refreshInterval && refreshInterval > 0) {
      const interval = setInterval(loadInfoDashAdmin, refreshInterval);
      return () => clearInterval(interval);
    }
  }, [refreshInterval]);

  if (loading) {
    return <MiniLoadingCard />;
  } else {
    return (
      <MiniStatisticsCard
        title={{ text: "Relógios de Pontos" }}
        count={countAllVoIPs}
        percentage={{
          color: countVoIPsOffLines > 0 ? "error" : "success",
          text:
            countVoIPsOffLines > 0
              ? `${countVoIPsOnLines}% Offline (${countVoIPsOffLines})`
              : `100% Online`,
        }}
        icon={{
          color: countVoIPsOffLines > 0 ? "error" : "success",
          component: <IoCallSharp size="22px" color="white" />,
        }}
      />
    );
  }
}

export default MiniCardClocks;
