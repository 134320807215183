/* eslint-disable radix */
/* eslint-disable no-plusplus */
/* eslint-disable no-undef */
/* eslint-disable no-useless-escape */
/* eslint-disable eqeqeq */
import moment from "moment";

export const uniqueUid = (bits) => {
  function chr4() {
    return Math.random().toString(16).slice(-4);
  }
  let strTmp = "";
  for (let i = 0; i <= bits / 4; i++) {
    strTmp += chr4();
  }
  return strTmp;
};

export const logStruct = (func, error, reqData = "") => {
  return {
    date: moment().format("DD/MM/YYYY HH:MM:ss"),
    func,
    error,
    reqData,
  };
};

export const validateEmail = (email) => {
  const pattern =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(email);
};

export const validateEmailInstitutional = (email, institutional = "mavo.com.br") => {
  const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@mavo.com.br$/;
  return pattern.test(email);
};

export const validateCheckEmail = (email) => {
  const pattern =
    /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
  return pattern.test(email);
};

export const validatePhone = (phone) => {
  const pattern = /^([+]971)?\d{9}$/;
  return pattern.test(phone);
};

export const validateCPF = (cpf) => {
  cpf = cpf.replace(/[^\d]+/g, "");
  if (cpf === "") {
    return false;
  }
  // Elimina CPFs invalidos conhecidos
  if (
    cpf.length != 11 ||
    cpf === "00000000000" ||
    cpf === "11111111111" ||
    cpf === "22222222222" ||
    cpf === "33333333333" ||
    cpf === "44444444444" ||
    cpf === "55555555555" ||
    cpf === "66666666666" ||
    cpf === "77777777777" ||
    cpf === "88888888888" ||
    cpf === "99999999999"
  ) {
    return false;
  }
  // Valida 1o digito
  let add = 0;
  for (let i = 0; i < 9; i++) {
    add = add + parseInt(cpf.charAt(i)) * (10 - i);
  }
  let rev = 11 - (add % 11);
  if (rev == 10 || rev == 11) rev = 0;
  if (rev != parseInt(cpf.charAt(9))) {
    return false;
  }
  // Valida 2o digito
  add = 0;
  for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev == 10 || rev == 11) rev = 0;

  if (rev != parseInt(cpf.charAt(10))) {
    return false;
  }
  return true;
};

export function TestaCPF(strCPF) {
  let Soma, Resto;
  // let Resto;
  if (
    strCPF.length != 11 ||
    strCPF === "00000000000" ||
    strCPF === "11111111111" ||
    strCPF === "22222222222" ||
    strCPF === "33333333333" ||
    strCPF === "44444444444" ||
    strCPF === "55555555555" ||
    strCPF === "66666666666" ||
    strCPF === "77777777777" ||
    strCPF === "88888888888" ||
    strCPF === "99999999999"
  ) {
    console.log("validateCPF Invalidos");
    return false;
  }

  console.log(">>> TestaCPF", strCPF);
  Soma = 0;
  for (let i = 1; i <= 9; i++) {
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  }
  Resto = (Soma * 10) % 11;

  if (Resto == 10 || Resto == 11) Resto = 0;
  if (Resto != parseInt(strCPF.substring(9, 10))) return false;

  Soma = 0;
  for (let i = 1; i <= 10; i++) {
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  }
  Resto = (Soma * 10) % 11;

  if (Resto == 10 || Resto == 11) Resto = 0;
  if (Resto != parseInt(strCPF.substring(10, 11))) return false;
  return true;
}

export const validateCNPJ = (cnpj) => {
  cnpj = cnpj.replace(/[^\d]+/g, "");

  if (cnpj == "") return false;

  if (cnpj.length != 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj == "00000000000000" ||
    cnpj == "11111111111111" ||
    cnpj == "22222222222222" ||
    cnpj == "33333333333333" ||
    cnpj == "44444444444444" ||
    cnpj == "55555555555555" ||
    cnpj == "66666666666666" ||
    cnpj == "77777777777777" ||
    cnpj == "88888888888888" ||
    cnpj == "99999999999999"
  )
    return false;

  // Valida DVs
  tamanho = cnpj.length - 2;
  numeros = cnpj.substring(0, tamanho);
  digitos = cnpj.substring(tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(0)) return false;

  tamanho += 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(1)) return false;

  return true;
};

export const WhatsStateByDDDPhone = (id) => {
  const groupDDD = [
    { id: "11|12|13|14|15|16|17|18|19", sigle: "SP" },
    { id: "21|22|24", sigle: "RJ" },
    { id: "31|32|33|34|35|37|38", sigle: "MG" },
    { id: "27|28", sigle: "ES" },
    { id: "61", sigle: "DF" },
    { id: "62|64", sigle: "GO" },
    { id: "65|66", sigle: "MT" },
    { id: "67", sigle: "MS" },
    { id: "82", sigle: "AL" },
    { id: "71|73|74|75|77", sigle: "BA" },
    { id: "85|88", sigle: "CE" },
    { id: "98|99", sigle: "MA" },
    { id: "83", sigle: "PB" },
    { id: "81|87", sigle: "PE" },
    { id: "86|89", sigle: "PI" },
    { id: "84", sigle: "RN" },
    { id: "79", sigle: "SE" },
    { id: "68", sigle: "AC" },
    { id: "96", sigle: "AM" },
    { id: "92|97", sigle: "AM" },
    { id: "91|93|94", sigle: "PA" },
    { id: "69", sigle: "RO" },
    { id: "95", sigle: "RR" },
    { id: "63", sigle: "TO" },
  ];

  const UF = groupDDD.filter((x) => {
    const idUF = x.id.split("|").filter((i) => i === id).length > 0;
    if (idUF) {
      return x.sigle[0];
    }
    return false;
  });

  return UF.length > 0 ? UF[0].sigle : "";
};

function cep(e) {
  e.currentTarget.maxLength = 9;
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, "");
  value = value.replace(/^(\d{5})(\d)/, "$1-$2");
  e.currentTarget.value = value;
  return e;
}

function currency(e) {
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d)(\d{2})$/, "$1,$2");
  value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
  e.currentTarget.value = value;
  return e;
}

function cpf(e) {
  e.currentTarget.maxLength = 14;
  let value = e.currentTarget.value;
  if (!value.match(/^(\d{3}).(\d{3}).(\d{3})-(\d{2})$/)) {
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{3})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d{2})$/, "$1-$2");
    e.currentTarget.value = value;
  }
  return e;
}

function phone(e) {
  e.currentTarget.maxLength = 14;
  let value = e.currentTarget.value;
  e.currentTarget.value = value.toString().replace(/(\d{2})(\d{5})(\d{4})/, "($1)$2-$3");
  return e;
}
