import { useState, useEffect } from "react";
import { useLocation, Redirect } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";

// Vision UI Dashboard React example components
import Footer from "examples/Footer";
import BasicLayout from "../components/BasicLayout";

function DashOccurrence() {
  const { search } = useLocation();
  const actionCommand = search.replace("?", "").split("=")[0];
  const actionExec = search.replace("?", "").split("=")[1];
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log(">>> actionCommand", actionCommand, " actionExec", actionExec);
  }, [false]);

  return (
    <BasicLayout
      gotoPage="/admin"
      action={{
        type: "internal",
        label: "Logout",
        route: "/",
        exec: () => {
          localStorage.clear();
        },
        color: "white",
      }}
    >
      <Grid item xs={12} lg={7} xl={12}></Grid>
      <Footer />
    </BasicLayout>
  );
}

function CheckLogin() {
  const location = useLocation();
  const isLoggedStorage = localStorage.getItem("logged");
  return isLoggedStorage ? (
    <DashOccurrence />
  ) : (
    <Redirect
      to={
        "/login?redirect=" +
        location.pathname +
        (location.search !== "" ? `${location.search}` : "")
      }
    />
  );
}

export default CheckLogin;
