import { useState, useEffect } from "react";

import { experimentalStyled as styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

import Grid from "@mui/material/Grid";

import { IoEllipsisVerticalSharp } from "react-icons/io5";

import { BarChart, Bar } from "recharts";

import moment from "moment";

import VuiTypography from "components/VuiTypography";
import VuiBox from "components/VuiBox";
import VuiLabel from "components/VuiLabel";

import breakpoints from "assets/theme/base/breakpoints";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".");
}

function formatReal(x) {
  return x.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
}

function colorAlertGraphics(alertToner) {
  if (alertToner >= 20 && alertToner <= 30) return "alert";
  if (alertToner >= 1 && alertToner <= 20) return "error";
  return "success";
}

const CardInfoPrinter = styled(Paper)(({ theme }) => ({
  backgroundColor: "#1A2027",
  color: theme.palette.text.secondary,
  borderRadius: 10,
}));

function VuiInfoItemPrinter({ item, isGroupAdmin, index }) {
  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
        setMobileNavbar(false);
      } else {
        setMobileView(false);
        setMobileNavbar(false);
      }
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener("resize", displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  if (item) {
    const levelTonel10 = parseFloat(item.maxLevelToner / 1.1).toFixed(0);
    const levelTonel20 = parseFloat(item.maxLevelToner / 1.2).toFixed(0);
    const levelTonel30 = parseFloat(item.maxLevelToner / 1.3).toFixed(0);

    let alertToner = 0;

    if (item.currentLevelToner > levelTonel30) alertToner = 30;
    if (item.currentLevelToner > levelTonel20) alertToner = 20;
    if (item.currentLevelToner > levelTonel10) alertToner = 10;

    const data = [
      {
        name: "Toner",
        y:
          100 -
          (item.crossBilling === 0
            ? item.currentLevelToner / item.maxLevelToner
            : item.maxLevelToner / item.currentLevelToner) *
            100,
        x:
          (item.crossBilling === 0
            ? item.currentLevelToner / item.maxLevelToner
            : item.maxLevelToner / item.currentLevelToner) * 100,
      },
    ];

    return (
      <CardInfoPrinter>
        {/* Title Card */}
        <VuiBox
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 10,
          }}
        >
          <VuiTypography color="white" variant="button" fontWeight="bold">
            {item.initials}
          </VuiTypography>
          <VuiTypography
            color="white"
            variant="button"
            fontWeight="regular"
            noWrap={true}
            ml="10px"
            mr="10px"
          >
            {item.name}
          </VuiTypography>
          <VuiTypography color="white" variant="lg">
            <IoEllipsisVerticalSharp />
          </VuiTypography>
        </VuiBox>

        {/* Body Card */}
        {/* sijTj&uV */}
        <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 3, sm: 2, md: 2 }} p={"20px"}>
          <VuiBox width="50%">
            <VuiLabel title="Informações Físicas" sizeTitle={14} fontWeightTitle="bold" />
            <VuiLabel title="Local:" subtitle={item.location} fontWeightSubTitle="bold" />
            <VuiLabel title="Marca:" subtitle={item.factoryPrinter} fontWeightSubTitle="bold" />
            <VuiLabel
              title="Modelo:"
              subtitle={item.modelPrinter ? item.modelPrinter : "No Search"}
              fontWeightSubTitle="bold"
            />
            <VuiLabel
              title="Número Serial:"
              subtitle={item.serialPrinter ? item.serialPrinter : ""}
              fontWeightSubTitle="bold"
            />
            <VuiLabel title="HostName:" subtitle={item.hostname} fontWeightSubTitle="bold" />
            <VuiLabel title="Tipo:" subtitle={item.typePrinter} fontWeightSubTitle="bold" />
            <VuiLabel
              title="Colorida:"
              subtitle={item.isColorPrinter ? "Sim" : "Não"}
              fontWeightSubTitle="bold"
            />
            <VuiLabel title="" sizeTitle={12} />
            <VuiLabel
              title="Informações de Bilhetagem Atual"
              sizeTitle={14}
              fontWeightTitle="bold"
            />
            <VuiLabel
              title="Franquia Mês:"
              subtitle={item.countPageFranchise ? numberWithCommas(item.countPageFranchise) : 0}
              fontWeightSubTitle="bold"
            />
            <VuiLabel
              title="Contagem Inicial:"
              subtitle={item.countPageInit ? numberWithCommas(item.countPageInit) : 0}
              fontWeightSubTitle="bold"
            />
            <VuiLabel
              title="Data Contagem Inicial:"
              subtitle={
                item.dateCountPageInit
                  ? moment(item.dateCountPageInit).format("DD/MM/YYYY")
                  : moment().format("DD/MM/YYYY")
              }
              fontWeightSubTitle="bold"
            />
            <VuiLabel title="" sizeTitle={12} />
            <VuiLabel
              title="Contagem Atual:"
              subtitle={item.countPage ? numberWithCommas(item.countPage) : 0}
              fontWeightSubTitle="bold"
            />
            <VuiLabel
              title="Consumo Mês:"
              subtitle={item.currentBilling ? numberWithCommas(item.currentBilling) : 0}
              fontWeightSubTitle="bold"
            />
            <VuiLabel
              title="Excedente Mês:"
              subtitle={item.countPageOverPlus ? numberWithCommas(item.countPageOverPlus) : 0}
              fontWeightSubTitle="bold"
            />
            <VuiLabel
              title="Percentual:"
              subtitle={
                item.percentageBilling && !isNaN(item.percentageBilling)
                  ? item.percentageBilling > 100
                    ? "100% - Excedente: " +
                      parseFloat(numberWithCommas(item.percentageBilling - 100)).toFixed(2) +
                      "%"
                    : parseFloat(item.percentageBilling)
                        .toFixed(2)
                        .replace(".", ",")
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + "%"
                  : 0
              }
              fontWeightSubTitle="bold"
            />
            {isGroupAdmin && (
              <>
                {item.currentInvoiceMonth && (
                  <VuiLabel
                    title="Fatura do Mês:"
                    subtitle={`R$ ${formatReal(
                      item.currentInvoiceMonth ? item.currentInvoiceMonth : 0
                    )}`}
                    fontWeightSubTitle="bold"
                  />
                )}
              </>
            )}

            <VuiLabel title="" sizeTitle={12} />
            <VuiLabel title="Informações de Manutenção" sizeTitle={14} fontWeightTitle="bold" />

            <VuiLabel
              title="IP Address:"
              subtitle={item.ip}
              openLinkClick={item.status ? `http://${item.ip}/` : null}
            />
          </VuiBox>

          <VuiBox width="50%">
            <VuiBox>
              <VuiBox display="flex" justifyContent="center" alignItems="center">
                {item.isColorPrinter ? (
                  <VuiBox display="flex" flexDirection="row">
                    <BarChart width={40} height={250} data={data}>
                      <Bar dataKey="x" stackId="a" fill="#000" />
                      <Bar dataKey="y" stackId="a" fill="#000" />
                    </BarChart>
                    <BarChart width={40} height={250} data={data}>
                      <Bar dataKey="x" stackId="a" fill="#00FFFF" />
                      <Bar dataKey="y" stackId="a" fill="#00FFFF" />
                    </BarChart>
                    <BarChart width={40} height={250} data={data}>
                      <Bar dataKey="x" stackId="a" fill="#FFFF00" />
                      <Bar dataKey="y" stackId="a" fill="#FFFF00" />
                    </BarChart>
                    <BarChart width={40} height={250} data={data}>
                      <Bar dataKey="x" stackId="a" fill="#FF00FF" />
                      <Bar dataKey="y" stackId="a" fill="#FF00FF" />
                    </BarChart>
                  </VuiBox>
                ) : (
                  <VuiBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <BarChart width={60} height={180} data={data}>
                      {/* <YAxis /> */}
                      <Bar dataKey="x" stackId="a" fill="#ff3c38" />
                      <Bar dataKey="y" stackId="a" fill="#617a34" />
                    </BarChart>
                    <VuiBox display="flex" flexDirection="row">
                      {/* <VuiBox display="flex" flexDirection="column" alignItems="center">
                        <VuiLabel
                          title={`${isNaN(item.calcValue) ? 0 : item.calcValue}%`}
                          colorTitle="success"
                          sizeTitle={32}
                          fontWeightTitle="bold"
                        />
                        <VuiLabel
                          title="Disponível"
                          colorTitle="success"
                          sizeTitle={12}
                          fontWeightTitle="bold"
                        />
                      </VuiBox>
                      <VuiBox width="20px"></VuiBox> */}
                      <VuiBox display="flex" flexDirection="column" alignItems="center">
                        {item.calcValue === "100" && (
                          <VuiLabel
                            title={item.calcValue === "100" ? "Utilizado" : ""}
                            colorTitle={colorAlertGraphics(alertToner)}
                            sizeTitle={18}
                            fontWeightTitle="bold"
                          />
                        )}
                        <VuiLabel
                          title={`${isNaN(item.calcValue) ? 0 : item.calcValue}%`}
                          colorTitle={colorAlertGraphics(alertToner)}
                          sizeTitle={32}
                          fontWeightTitle="bold"
                        />
                        <VuiLabel
                          title={item.calcValue === "100" ? "Trocar o Toner" : "Disponível"}
                          colorTitle={colorAlertGraphics(alertToner)}
                          sizeTitle={18}
                          fontWeightTitle="bold"
                        />
                      </VuiBox>
                    </VuiBox>
                  </VuiBox>
                )}
              </VuiBox>
            </VuiBox>
            {!mobileView && (
              <VuiBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt="20px"
                mb="20px"
              >
                {alertToner === 10 && (
                  <VuiLabel
                    title={"Atenção! Solicitar TONER para Substituição"}
                    colorTitle="error"
                    fontWeightTitle="bold"
                    sizeTitle={24}
                    textAlignTitle="center"
                  />
                )}
                {alertToner === 20 && (
                  <VuiLabel
                    title={`Segundo Alerta de troca de Toner`}
                    colorTitle="alert"
                    fontWeightTitle="bold"
                    sizeTitle={24}
                    textAlignTitle="center"
                  />
                )}
                {alertToner === 30 && (
                  <VuiLabel
                    title={`Primeiro Alerta de troca de Toner!`}
                    colorTitle="success"
                    fontWeightTitle="bold"
                    sizeTitle={24}
                    textAlignTitle="center"
                  />
                )}
              </VuiBox>
            )}
          </VuiBox>
        </Grid>

        <VuiBox display="flex" justifyContent="center" alignItems="center" mt="20px" mb="20px">
          <VuiBox>
            <VuiLabel
              title={item.status ? "Online" : "Offline"}
              sizeTitle={32}
              colorTitle={item.status ? "online" : "error"}
              fontWeightTitle="bold"
            />
          </VuiBox>
        </VuiBox>

        <VuiBox display="flex" justifyContent="flex-end" alignItems="center" p="10px">
          <VuiBox>
            <VuiLabel
              title={`Última Atualização ${item.updatedAt}`}
              sizeTitle={11}
              fontWeightTitle="bold"
            />
          </VuiBox>
        </VuiBox>
      </CardInfoPrinter>
    );
  } else {
    return <CardInfoPrinter />;
  }
}

export default VuiInfoItemPrinter;
