import { useState, useEffect } from "react";
import { useHistory, useLocation, Redirect } from "react-router-dom";

import { IoCheckmarkSharp } from "react-icons/io5";
import { IoCheckmarkDoneSharp } from "react-icons/io5";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiLabel from "components/VuiLabel";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiButtonCustom from "components/VuiButtonCustom";
import VuiCircularWithValueLabel from "components/VuiCircularWithValueLabel";

import Footer from "examples/Footer";

// Images
import Loading from "examples/Icons/Loading";

import useWindowDimensions from "hooks/useWindowDimensions";
import colors from "assets/theme/base/colors";

import api from "services/api";

import { Container, Content } from "./styles";

function VuiRow() {
  return <div style={{ height: 30 }}></div>;
}

function LoadingResetPassword() {
  const { height, width } = useWindowDimensions();
  return (
    <VuiBox sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: null }}>
      <Loading />
    </VuiBox>
  );
}

function FocusNextField(fieldName) {
  const nextfield = document.querySelector(`input[name=${fieldName}]`);
  nextfield.focus();
}

function CircularProgressThickness({ label, timeOut = 100, height = 600, onExec, onProgress }) {
  const [progress, setProgress] = useState(1);
  let msgLabel = "";

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        onProgress(prevProgress);
        return prevProgress >= 101 ? 1 : prevProgress + 1;
      });
    }, timeOut);
    return () => {
      clearInterval(timer);
    };
  }, []);

  if (progress === 100) {
    onExec();
  }

  const strLabel = label.filter((x) => {
    if (progress >= x.min && progress <= x.max) {
      return true;
    }
  });

  msgLabel = strLabel.length > 0 ? strLabel[0].msg : msgLabel;

  return (
    <div
      style={{ display: "flex", justifyContent: "center", alignItems: "center", height: height }}
    >
      <VuiCircularWithValueLabel label={msgLabel} value={progress} size="160px" thickness={0.5} />
    </div>
  );
}

async function saveNewPassword(states) {
  const saveResult = await api.post(`users/changeHashPassword`, states);
  // console.log(">>> saveNewPassword:states:", states, saveResult);
  return saveResult.data.data.response;
}

function FormResetPassword({ states }) {
  const greetings = ["Boa Madrugada!", "Bom dia!", "Boa tarde!", "Boa noite!"];
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [isSaveNewPassword, setIsSaveNewPassword] = useState(false);
  const [isFinishProcess, isSetFinishProcess] = useState(false);
  const [saveResultChange, setSaveResultChange] = useState(false);

  const [isDonneCheck, setIsDonneCheck] = useState({
    check1: false,
    check2: false,
    check3: false,
    check4: false,
    check5: false,
    check6: false,
  });

  useEffect(() => {
    setIsDonneCheck({
      check1: /[A-Z]/.test(password[0]),
      check2: /[a-z]/.test(password),
      check3: /[0-9]/.test(password),
      check4: /[!@#$%ˆ&*()]/.test(password),
      check5: password.length >= 8,
      check6: password === passwordConfirm && password.length > 0 && passwordConfirm.length > 0,
    });
  }, [password, passwordConfirm]);

  // useEffect(() => {
  //   console.log(">>> FormResetPassword:states:", states);
  // }, [false]);

  if (isFinishProcess) {
    return (
      <Grid container spacing={3} p={3}>
        <Grid item xs={12} lg={12} xl={12}>
          <VuiLabel title="Parabéns!" sizeTitle={42} />
          <VuiLabel
            title="Sua credecial foi redefinida. Você será redirecionado para a pagina principal da plataforma."
            sizeTitle={28}
          />
        </Grid>
        <Grid item xs={12} lg={6} xl={10}>
          <VuiRow />
          <VuiRow />
        </Grid>

        <Grid item xs={12} lg={6} xl={10}></Grid>
        <Grid item xs={12} lg={6} xl={2}>
          <VuiButtonCustom title="Fechar" color="dark" onClick={() => {}} />
        </Grid>
      </Grid>
    );
  }

  if (isSaveNewPassword) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6} xl={12}>
          <CircularProgressThickness
            height={null}
            label={[
              { min: 1, max: 79, value: 1, msg: "Aguarde... Salvando informações" },
              { min: 80, max: 100, value: 100, msg: "Aguarde... Finalizando o processo" },
            ]}
            onExec={() => {
              setSaveResultChange(true);
            }}
            onProgress={async (n) => {
              {
                if (n === 45) {
                  console.log(">>> Salvar informações aqui", n);
                  const result = await saveNewPassword({
                    ...states,
                    newPassword: password,
                  });
                  setSaveResultChange(result);
                }
                if (n === 85) {
                  console.log(">>> Finalizando processo", n);
                }
                if (n >= 99) {
                  isSetFinishProcess(true);
                }
              }
            }}
          />
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6} xl={12}>
          <VuiLabel title={greetings[states.greetings]} sizeTitle={32} />{" "}
          <VuiLabel
            title={`${states.gender.substring(0, 1) === "M" ? "Senhor" : "Senhora"}, ${
              states.name
            }.`}
            sizeTitle={20}
          />
        </Grid>
        <Grid item xs={12} lg={6} xl={12}>
          <VuiLabel
            title="Redefinir senha de acesso, favor informar os campos abaixo:"
            sizeTitle={18}
          />
        </Grid>
        {/*  */}
        {/* Line 5 */}
        <Grid item xs={12} lg={12} xl={6}>
          <VuiLabel title="Senha" />
          <VuiInput
            name="password"
            type="password"
            placeholder="Informe uma senha forte com Maiusculas, caracter especiais e padrão"
            value={password}
            onChange={(e) => {
              const value = e.target.value;
              setPassword(value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.keyCode === 13) FocusNextField("password");
            }}
          />
          <div style={{ fontSize: 11, color: "#fff", marginTop: 5, marginBottom: 5 }}>
            Sua senha, deverá conter no mínimo oito caracteres e deverá obedecer os requisitos:
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              {isDonneCheck.check1 ? (
                <IoCheckmarkDoneSharp color={colors.online.main} size={18} />
              ) : (
                <IoCheckmarkSharp size={18} />
              )}
              <p style={{ marginLeft: 5 }}>Primeira letras maiúsculas (A-Z)</p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              {isDonneCheck.check2 ? (
                <IoCheckmarkDoneSharp color={colors.online.main} size={18} />
              ) : (
                <IoCheckmarkSharp size={18} />
              )}
              <p style={{ marginLeft: 5 }}>Letras minúsculas (a-z)</p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              {isDonneCheck.check3 ? (
                <IoCheckmarkDoneSharp color={colors.online.main} size={18} />
              ) : (
                <IoCheckmarkSharp size={18} />
              )}
              <p style={{ marginLeft: 5 }}>Números (0-9)</p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              {isDonneCheck.check4 ? (
                <IoCheckmarkDoneSharp color={colors.online.main} size={18} />
              ) : (
                <IoCheckmarkSharp size={18} />
              )}
              <p style={{ marginLeft: 5 }}>Caracteres especiais ($, %, @, ...)</p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              {isDonneCheck.check5 ? (
                <IoCheckmarkDoneSharp color={colors.online.main} size={18} />
              ) : (
                <IoCheckmarkSharp size={18} />
              )}
              <p style={{ marginLeft: 5 }}>Tamanho da senha igual ou maior que 8 dígitos</p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              {isDonneCheck.check6 ? (
                <IoCheckmarkDoneSharp color={colors.online.main} size={18} />
              ) : (
                <IoCheckmarkSharp size={18} />
              )}
              <p style={{ marginLeft: 5 }}>Confirmação da Senha</p>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} lg={12} xl={6}>
          <VuiLabel title="Confirme a Senha" />
          <VuiInput
            name="passwordConfirm"
            type="password"
            placeholder="Confirme a senha informada"
            value={passwordConfirm}
            onChange={(e) => {
              const value = e.target.value;
              setPasswordConfirm(value);
            }}
          />
        </Grid>{" "}
        {/*  */}
        <Grid item xs={12} lg={6} xl={10}></Grid>
        <Grid item xs={12} lg={6} xl={2}>
          <VuiButton
            color="success"
            disabled={
              !(
                isDonneCheck.check1 &&
                isDonneCheck.check2 &&
                isDonneCheck.check3 &&
                isDonneCheck.check4 &&
                isDonneCheck.check5 &&
                isDonneCheck.check6
              )
            }
            onClick={async () => {
              setIsSaveNewPassword(true);
            }}
            sx={{ height: 36, width: "100%" }}
          >
            Salvar
          </VuiButton>
        </Grid>
      </Grid>
    );
  }
}

function InvalidHashReset() {
  return (
    <Container>
      <Content>
        <VuiLabel title="Ops!" sizeTitle={42} />
        <VuiRow />
        <VuiRow />
        <VuiLabel
          title="Solicitação de redefinição invalida. Você sera redirecionado para a pagina principal."
          sizeTitle={28}
        />
        <VuiRow />
      </Content>
      <Footer />
    </Container>
  );
}

function ExpiredHashReset() {
  return (
    <Container>
      <Content>
        <VuiLabel title="Ops!" sizeTitle={42} />
        <VuiRow />
        <VuiRow />
        <VuiLabel
          title="Solicitação de redefinição de Senha expirou. Solicite novamente."
          sizeTitle={28}
        />
        <VuiRow />
      </Content>
      <Footer />
    </Container>
  );
}

function ResetPassword() {
  const history = useHistory();
  const { height, width } = useWindowDimensions();
  const { search } = useLocation();
  const [loading, setLoading] = useState(true);
  const [isRedirect, setIsRedirect] = useState(false);
  const [states, setStates] = useState({});

  useEffect(() => {
    const checkOutHash = search.replace("?", "").split("&");
    async function verifyHash(hashEmail, hashCheck) {
      const checkResult = await api.get(`users/checkHashReset/?${hashEmail}-${hashCheck}`);

      if (checkResult.data.data) {
        setStates({
          ...checkResult.data.data,
          hashEmail: hashEmail,
        });
      }

      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
    verifyHash(checkOutHash[0], checkOutHash[1]);
  }, [false]);

  useEffect(() => {
    console.log(">>> ", isRedirect);
    if (isRedirect) {
      history.push("/dashboard");
    }
  }, [isRedirect]);

  if (states && states.hashExpire && !loading) {
    setTimeout(() => {
      setIsRedirect(true);
    }, 5000);
    return <ExpiredHashReset />;
  }

  if (states && !states.check && !loading) {
    setTimeout(() => {
      setIsRedirect(true);
    }, 5000);
    return <InvalidHashReset />;
  }

  return (
    <Container>
      <Content>
        {loading ? <LoadingResetPassword /> : <FormResetPassword states={states} />}
      </Content>
      <Footer />
    </Container>
  );
}

export default ResetPassword;
