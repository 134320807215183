// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import VuiTypography from "components/VuiTypography";
import colors from "assets/theme/base/colors";

function MiniStatisticsCard({
  bgColor,
  title,
  count,
  percentage,
  icon,
  direction,
  menuController,
  buttonAddImage,
  buttonEditImage,
  buttonDeleteImage,
}) {
  const { info } = colors;

  // console.log(">>> menuController:", menuController);
  // console.log(">>> buttonAddImage:", buttonAddImage);

  return (
    <Card sx={{ padding: "17px" }}>
      <VuiBox>
        <VuiBox>
          <Grid container flexDirection="row" alignItems="center">
            {direction === "left" ? (
              <Grid item>
                <VuiBox
                  bgColor={icon.color}
                  color="white"
                  width="2.5rem"
                  height="2.5rem"
                  marginLeft="auto"
                  borderRadius="sm"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  shadow="md"
                >
                  {icon.component}
                </VuiBox>
              </Grid>
            ) : null}

            <Grid item xs={8}>
              <VuiBox ml={direction === "left" ? 2 : 0} lineHeight={1}>
                <VuiTypography
                  variant="caption"
                  color={bgColor === "white" ? "text" : "white"}
                  opacity={bgColor === "white" ? 1 : 0.7}
                  // textTransform="capitalize"
                  fontWeight={title.fontWeight}
                >
                  {title.text}
                </VuiTypography>
                <VuiTypography variant="subtitle1" fontWeight="bold" color="white">
                  {count}{" "}
                  <VuiTypography variant="button" color={percentage.color} fontWeight="bold">
                    {percentage.text}
                  </VuiTypography>
                </VuiTypography>
              </VuiBox>
            </Grid>

            {direction === "right" ? (
              <Grid item xs={4}>
                <VuiBox
                  bgColor={icon.color}
                  color="white"
                  width="2.5rem"
                  height="2.5rem"
                  marginLeft="auto"
                  borderRadius="sm"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  shadow="md"
                >
                  {icon.component}
                </VuiBox>
              </Grid>
            ) : null}

            {buttonAddImage && direction === "left" ? (
              <VuiButton
                onClick={() => buttonAddImage.icon.exec()}
                iconOnly
                variant="text"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "2.5rem",
                  height: "2.5rem",
                  borderRadius: 5,
                  marginLeft: "auto",
                  marginRight: 0,
                  backgroundColor: buttonAddImage.icon.color,
                }}
              >
                {buttonAddImage.icon.component}
              </VuiButton>
            ) : null}

            {buttonEditImage && direction === "left" ? (
              <VuiButton
                onClick={() => buttonEditImage.icon.exec()}
                iconOnly
                variant="text"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "2.5rem",
                  height: "2.5rem",
                  borderRadius: 5,
                  marginLeft: 10,
                  marginRight: 0,
                  backgroundColor: buttonEditImage.icon.color,
                }}
              >
                {buttonEditImage.icon.component}
              </VuiButton>
            ) : null}

            {buttonDeleteImage && direction === "left" ? (
              <VuiButton
                onClick={() => buttonDeleteImage.icon.exec()}
                iconOnly
                variant="text"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "2.5rem",
                  height: "2.5rem",
                  borderRadius: 5,
                  marginLeft: 10,
                  marginRight: 0,
                  backgroundColor: buttonDeleteImage.icon.color,
                }}
              >
                {buttonDeleteImage.icon.component}
              </VuiButton>
            ) : null}

            {menuController && direction === "left" ? (
              <VuiBox
                bgColor={menuController.icon.color}
                color="white"
                width="2.5rem"
                height="2.5rem"
                marginLeft="10px"
                borderRadius="sm"
                display="flex"
                justifyContent="center"
                alignItems="center"
                shadow="md"
              >
                {menuController.icon.component}
              </VuiBox>
            ) : null}
          </Grid>
        </VuiBox>
      </VuiBox>
    </Card>
  );
}

// Setting default values for the props of MiniStatisticsCard
MiniStatisticsCard.defaultProps = {
  bgColor: "white",
  title: {
    fontWeight: "medium",
    text: "",
  },
  percentage: {
    color: "success",
    text: "",
  },
  direction: "right",
};

// Typechecking props for the MiniStatisticsCard
MiniStatisticsCard.propTypes = {
  bgColor: PropTypes.oneOf([
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  title: PropTypes.PropTypes.shape({
    fontWeight: PropTypes.oneOf(["light", "regular", "medium", "bold"]),
    text: PropTypes.string,
  }),
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  icon: PropTypes.shape({
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
    component: PropTypes.node.isRequired,
  }).isRequired,
  direction: PropTypes.oneOf(["right", "left"]),
};

export default MiniStatisticsCard;
