import VuiTypography from "components/VuiTypography";
import { Icon } from "@mui/material";

const VuiOpenLink = ({
  title,
  colorTitle = "white",
  fontSize = "1.125rem",
  fontWeight = "normal",
  variant = "sm",
  url = null,
  icon = "launch",
  iconSize = "medium",
  iconSide = "right",
  image,
  imageSize = 24,
  imageSide = "left",
  target = "_blank",
  onClick = () => {},
}) => (
  <p>
    <VuiTypography
      component="a"
      target={target}
      href={url}
      variant={variant}
      color={colorTitle}
      onClick={onClick}
      sx={{
        fontSize: { fontSize },
        fontWeight: { fontWeight },
        mr: "5px",
        display: "inline-flex",
        alignItems: "center",
        cursor: "pointer",
        "& .material-icons-round": {
          fontSize: "1.125rem",
          transform: `translate(2px, -0.5px)`,
          transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
        },
        "&:hover .material-icons-round, &:focus  .material-icons-round": {
          transform: `translate(6px, -0.5px)`,
        },
      }}
    >
      {image && imageSide === "left" && (
        <img src={image} alt={""} loading="lazy" style={{ height: imageSize }} />
      )}

      {icon && iconSide === "left" && (
        <Icon sx={{ fontSize: iconSize, fontWeight: { fontWeight }, mr: "10px", ml: "5px" }}>
          {icon}
        </Icon>
      )}

      {title}

      {image && imageSide === "right" && (
        <img src={image} alt={""} loading="lazy" style={{ height: imageSize }} />
      )}

      {icon && iconSide === "right" && (
        <Icon sx={{ fontSize: iconSize, fontWeight: { fontWeight }, mr: "10px", ml: "5px" }}>
          {icon}
        </Icon>
      )}
    </VuiTypography>
  </p>
);

export default VuiOpenLink;
