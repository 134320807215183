import React, { useState, useEffect } from "react";
import { Card } from "@mui/material";
import VuiBox from "components/VuiBox";
import colors from "assets/theme/base/colors";

import { Carousel } from "components/VuiCarousel";

import MiniCardBanner from "../../components/MiniCardBanner";
import BannerAction from "../../components/BannerCrud";

// Service Axios
import api from "services/api";

//
const ACTION_NONE = 0;
const ACTION_ADD = 1;
const ACTION_EDIT = 2;
const ACTION_DELETE = 3;

const captionStyle = {
  fontSize: "1em",
  fontWeight: "bold",
};

const slideNumberStyle = {
  fontSize: "14px",
  fontWeight: "bold",
  top: -30,
};

function BannerShow() {
  const [action, setAction] = useState(ACTION_NONE);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [dataItem, setDataItem] = useState({});

  async function loadBanner() {
    setLoading(true);
    const resBanner = await api.get("banner");
    setData(resBanner.data.data);
    setLoading(false);
  }

  useEffect(() => {
    loadBanner();
  }, [false]);

  if (data) {
    return (
      <>
        {action === ACTION_NONE ? (
          <>
            <MiniCardBanner
              countAllData={data.length}
              currentIndex={currentSlide}
              execAdd={() => {
                console.log(">>> execAdd", currentSlide);
                setAction(ACTION_ADD);
              }}
              execEdit={() => {
                console.log(">>> execEdit", currentSlide);
                setAction(ACTION_EDIT);
              }}
              execDelete={() => {
                console.log(">>> execDelete", currentSlide);
                setAction(ACTION_DELETE);
              }}
            />
            <VuiBox mt={3} />
            <Carousel
              data={data}
              // time={5000}
              width="100%"
              height="500px"
              captionStyle={captionStyle}
              radius="10px"
              slideNumber={false}
              slideNumberStyle={slideNumberStyle}
              captionPosition="bottom"
              automatic={false}
              dots={false}
              pauseIconColor="white"
              pauseIconSize="40px"
              slideBackgroundColor="dasrkgrey"
              slideImageFit="cover"
              thumbnails={false}
              thumbnailWidth="200px"
              style={{
                textAlign: "center",
                maxWidth: "1850px",
                maxHeight: "500px",
                margin: "0px auto",
              }}
              currentSlide={(item) => {
                setCurrentSlide(item.index);
                setDataItem(item);
              }}
              currentIndex={currentSlide}
            />
          </>
        ) : (
          <BannerAction
            action={action}
            onClick={(result) => setAction(ACTION_NONE)}
            data={dataItem}
          />
        )}
      </>
    );
  } else {
    return null;
  }
}

export default BannerShow;
