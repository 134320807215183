import { IoEllipsisVerticalSharp } from "react-icons/io5";
import { experimentalStyled as styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiLabel from "components/VuiLabel";
import VuiOpenLink from "components/VuiOpenLink";

import colors from "assets/theme/base/colors";

const CardInfoLink = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.black.modal,
  color: theme.palette.text.secondary,
  borderRadius: 10,
}));

function VuiInfoItemSecurityDevice({ item, index }) {
  if (item) {
    return (
      <CardInfoLink>
        <VuiBox
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 10,
          }}
        >
          <VuiTypography color="white" variant="button" fontWeight="bold">
            {item.initials}
          </VuiTypography>
          <VuiTypography
            color="white"
            variant="button"
            fontWeight="regular"
            noWrap={true}
            ml="10px"
            mr="10px"
          >
            {item.name}
          </VuiTypography>
          <VuiTypography color="white" variant="lg">
            <IoEllipsisVerticalSharp />
          </VuiTypography>
        </VuiBox>
        <VuiBox
          style={{
            display: "flex",
            flexDirection: "column",
            alignSelf: "flex-start",
            marginLeft: 10,
            marginRight: 10,
          }}
        >
          <VuiBox display="flex" flexDirection="row" alignItems="center">
            <VuiBox
              style={{
                width: "100%",
              }}
            >
              <VuiLabel title="Dispositivo:" subtitle={item.hostname} />
              <VuiLabel title="Marca:" subtitle={item.factory} />
              <VuiLabel title="Modelo:" subtitle={item.model} />
              <VuiLabel title="Número Serial:" subtitle={item.serial} />
              <VuiLabel title="Tipo:" subtitle={item.type_device} />
              <VuiLabel title="" />

              <VuiLabel
                title="IP Address:"
                subtitle={item.ip}
                openLinkClick={item.status ? `http://${item.ip}:${item.port_http}/` : null}
              />

              <VuiTypography
                style={{
                  marginTop: 10,
                  fontSize: 32,
                  fontWeight: "bold",
                  color: item.status ? colors.alert.online : colors.alert.offline,
                  textAlign: "center",
                }}
              >
                {item.status ? "Online" : "Offline"}
              </VuiTypography>
            </VuiBox>
          </VuiBox>
          <VuiBox mt="20px" mb="20px" flexDirection="column">
            <VuiTypography
              color="text"
              style={{
                fontSize: 11,
                fontWeight: "100",
                textAlign: "right",
              }}
            >
              {item.status ? "Última atualização" : "Falha da conexão desde"} {item.updatedAt}
            </VuiTypography>
          </VuiBox>
        </VuiBox>
      </CardInfoLink>
    );
  } else {
    return <CardInfoLink />;
  }
}

export default VuiInfoItemSecurityDevice;
