import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/pt-br";

// Vision UI Dashboard React Base Styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import boxShadows from "assets/theme/base/boxShadows";

// Vision UI Dashboard  helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { dark, white, grey, transparent, inputColors } = colors;
const { size, fontWeightRegular } = typography;
const { borderWidth, borderRadius } = borders;
const { buttonBoxShadow } = boxShadows;

const input = {
  styleOverrides: {
    root: {
      display: "flex !important",
      padding: `${pxToRem(8)} ${pxToRem(28)} ${pxToRem(8)} ${pxToRem(12)} !important`,
      border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
      borderRadius: `${borderRadius.lg} !important`,
      color: `${white.main} !important`,
      backgroundColor: `${inputColors.backgroundColor} !important`,
      "& fieldset": {
        border: "none",
      },
    },

    input: {
      width: "100% !important",
      height: `${pxToRem(22)}`,
      padding: "0 !important",

      "&::-webkit-input-placeholder": {
        color: `${dark.main} !important`,
      },
    },
  },
};

const calendarIcon = {
  styleOverrides: {
    root: {
      "& .material-icon, .material-icons-round, svg": {
        color: `${white.main} !important`,
      },
    },
  },
};

const theme = createTheme({
  components: {
    MuiInput: { ...input },
    MuiOutlinedInput: { ...input },
    MuiFilledInput: { ...input },
    MuiButtonBase: { ...calendarIcon },
  },
});

const isWeekend = (date) => {
  if (date) {
    const day = date.day();
    return day === 0 || day === 6;
  }
  return false;
};

export default function VuiDatePickers({ value, onChange, isOnlyWeek = true }) {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"pt-br"}>
        <DatePicker
          defaultValue={value}
          value={value}
          onChange={(e) => onChange(e)}
          shouldDisableDate={isOnlyWeek ? isWeekend : false}
          views={["year", "month", "day"]}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
}
