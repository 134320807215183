import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import { IoGrid } from "react-icons/io5";
import { IoList } from "react-icons/io5";

import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";

// Vision UI Dashboard React components
import VuiTypography from "components/VuiTypography";
import VuiBox from "components/VuiBox";
import VuiProgress from "components/VuiProgress";
import VuiInfoItemPrinter from "components/VuiInfoItemPrinter";
import VuiLoading from "components/VuiLoading";
import VuiLabel from "components/VuiLabel";

//
import breakpoints from "assets/theme/base/breakpoints";
import { navbarIconButton } from "examples/Navbars/DashboardNavbar/styles";

import api from "services/api";
import useWindowDimensions from "hooks/useWindowDimensions";
import colors from "assets/theme/base/colors";
import moment from "moment";

const padLeft = (num, places) => String(num).padStart(places, "0");

const ButtonIconItem = ({ name = "person", fontSizeIcon = "32px", onClick }) => {
  return (
    <IconButton
      size="small"
      color="inherit"
      sx={navbarIconButton}
      variant="contained"
      onClick={onClick}
    >
      <Icon fontSize={fontSizeIcon}>{name}</Icon>
    </IconButton>
  );
};

const INFO_TABLE_GRID = 0;
const INFO_TABLE_LIST = 1;

function Printers() {
  // const { columns, rows } = data();
  const [refreshInterval, setRefreshInterval] = useState(60000);
  const { height, width } = useWindowDimensions();
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState(null);
  const [countOffLines, setCountOfflines] = useState(0);
  const [countOnLines, setCountOnlines] = useState(0);
  const [countAllUnits, setCountAllUnits] = useState(0);
  const [isGroupAdmin, setIsGroupAdmin] = useState(0);
  const [toogleInfo, setToogleInfo] = useState(INFO_TABLE_GRID);
  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  const { search } = useLocation();
  const actionCommand = search.replace("?", "").split("=")[0];
  const actionExec = search.replace("?", "").split("=")[1];

  async function loadLinks(search) {
    const res = await api.get("printers");

    // console.log(">>> loadLinks:Printers", res.data);

    const data = [];
    res.data.map((x) => {
      if (x.enabled === 1) {
        data.push({
          ...x,
          currentInvoiceMonth: (x.currentBilling * x.valuePageFranchise).toFixed(2),
          countPageOverPlus: x.currentBilling - x.countPageFranchise,
          percentageBilling: ((x.currentBilling / x.countPageFranchise) * 100).toFixed(2),
          calcValue: ((x.currentLevelToner / x.maxLevelToner) * 100).toFixed(0),
          order: padLeft(((x.currentLevelToner / x.maxLevelToner) * 100).toFixed(0), 3),
          updatedAt: moment(x.updatedAt).format("DD/MM/YYYY HH:mm:ss"),
        });
      }
    });

    data
      .sort((a, b) => {
        if (a.order < b.order) {
          return -1;
        }
        if (a.order > b.order) {
          return 1;
        }
        return 0;
      })
      .sort((a, b) => Number(a.isColorPrinter) - Number(b.isColorPrinter))
      .sort((a, b) => Number(b.status) - Number(a.status));

    setCountOfflines(data.filter((x) => !x.status).length);
    setCountOnlines(data.filter((x) => x.status).length);
    setCountAllUnits(data.length + 1);

    // console.log(">>> Search:", search.replace("?", "").split("&"));
    // console.log(">>> Search:actionCommand:", actionCommand, "- Search:actionExec:", actionExec);

    if (actionCommand === "search" && actionExec === "offline") {
      setRows(data.filter((x) => !x.status));
    } else if (actionCommand === "search" && actionExec === "online") {
      setRows(data.filter((x) => x.status));
    } else if (
      actionCommand === "search" &&
      (actionExec !== "online" || actionExec !== "offline")
    ) {
      if (actionExec !== "") {
        setRows(data.filter((x) => x.initials === actionExec.toUpperCase()));
      } else {
        setRows(data);
      }
    } else {
      setRows(data);
    }
    setLoading(false);
  }

  useEffect(() => {
    loadLinks(search);
  }, [false]);

  useEffect(() => {
    setIsGroupAdmin(JSON.parse(localStorage.getItem("user")).id_group === 1);
    if (refreshInterval && refreshInterval > 0) {
      const interval = setInterval(loadLinks, refreshInterval);
      return () => clearInterval(interval);
    }
  }, [refreshInterval]);

  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        console.log(">>> mobileView .....:", true);
        console.log(">>> mobileNavbar ...:", false);

        setMobileView(true);
        setMobileNavbar(false);
      } else {
        setMobileView(false);
        setMobileNavbar(false);
      }
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener("resize", displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  return (
    <Card
      sx={{
        height: "100% !important",
      }}
    >
      <VuiBox
        sx={{
          "& th": {
            borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
              `${borderWidth[1]} solid ${grey[700]}`,
          },
          "& .MuiTableRow-root:not(:last-child)": {
            "& td": {
              borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                `${borderWidth[1]} solid ${grey[700]}`,
            },
          },
        }}
      >
        {loading ? (
          <VuiLoading />
        ) : (
          <VuiBox>
            <div
              style={{
                display: "grid",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                // fontSize: 24,
                // marginBottom: 20,
              }}
            >
              <VuiLabel
                title={`${countAllUnits} Impressoras Instaladas`}
                sizeTitle={"24px"}
                colorTitle="warning"
              />
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                {countOffLines > 0 ? (
                  <VuiLabel
                    title={`${
                      countOffLines > 0 && countOffLines < countAllUnits
                        ? ((countOffLines / countAllUnits) * 100).toFixed(2)
                        : 100
                    }%`}
                    sizeTitle={24}
                    subtitle="Offline"
                    sizeSubTitle={24}
                    colorSubTitle="error"
                    fontWeightSubTitle="bold"
                  />
                ) : (
                  <>
                    <div style={{ textAlign: "right", color: colors.success.focus }}>100%</div>
                    <div
                      style={{ fontWeight: "bold", textAlign: "right", color: colors.success.main }}
                    >
                      Online
                    </div>
                  </>
                )}
              </div>
              <VuiProgress
                value={(countOffLines / countAllUnits) * 100}
                color="error"
                label={false}
              />

              {mobileView ? null : (
                <VuiTypography
                  color="white"
                  variant="lg"
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  pt={"10px"}
                >
                  <ButtonIconItem
                    name="grid_view"
                    onClick={() => {
                      setToogleInfo(INFO_TABLE_GRID);
                    }}
                  />

                  <div style={{ width: 5 }} />

                  <ButtonIconItem
                    name="list_view"
                    onClick={() => {
                      setToogleInfo(INFO_TABLE_LIST);
                    }}
                  />
                </VuiTypography>
              )}
            </div>

            {toogleInfo === INFO_TABLE_GRID ? (
              <Box sx={{ flexGrow: 2 }}>
                <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 3, sm: 6, md: 18 }}>
                  {rows.map((item, index) => {
                    return (
                      <Grid item xs={6} sm={6} md={6} key={index}>
                        <VuiInfoItemPrinter item={item} isGroupAdmin={isGroupAdmin} />
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            ) : null}
          </VuiBox>
        )}
      </VuiBox>
    </Card>
  );
}

export default Printers;
