import moment from "moment";

function checkLocalDomain() {
  //
  // Value localDomin:
  //
  // intranet = localhost && monitor.seap.ma.br
  // internet = monitor.seap.ma.gov.br
  //
  const localDomain =
    window.location.host.split(":")[0] === "0.0.0.0"
      ? "localhost"
      : window.location.host.split(":")[0];

  const isIntraNet =
    localDomain === "localhost" ||
    localDomain === "monitor.seap.ma.br" ||
    localDomain === "dev.monitor.seap.ma.br" ||
    localDomain === "10.110.21.22" ||
    localDomain === "10.110.23.232" ||
    localDomain === "10.34.8.184";

  return { isIntraNet, localDomain };
}

export default checkLocalDomain;
