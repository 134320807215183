import React from "react";
import { Carousel } from "react-carousel-minimal";

const captionStyle = {
  fontSize: "1em",
  fontWeight: "bold",
};

const slideNumberStyle = {
  fontSize: "14px",
  fontWeight: "bold",
};

function BannerSystem({ data }) {
  return (
    <Carousel
      data={data}
      time={5000}
      width="100%"
      height="340px"
      radius="10px"
      // slideNumber={true}
      // slideNumberStyle={slideNumberStyle}
      captionPosition="bottom"
      captionStyle={captionStyle}
      automatic={true}
      dots={false}
      pauseIconColor="white"
      pauseIconSize="40px"
      slideBackgroundColor="dasrkgrey"
      slideImageFit="cover"
      thumbnails={false}
      thumbnailWidth="100px"
      showNavBtn={false}
      style={{
        textAlign: "center",
        maxWidth: "1600px",
        maxHeight: "900px",
        // margin: "0px auto",
      }}
    />
  );
}

export default BannerSystem;
