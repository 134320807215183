import { useLocation, Redirect } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";

import checkExpiredToken from "../../../helpers/checkExpiredToken";

// Vision UI Dashboard React example components
import Footer from "examples/Footer";
import BasicLayout from "../components/BasicLayout";

import Links from "./components/Links";

function ListLinks() {
  return (
    <BasicLayout
      gotoPage="/admin"
      action={{
        type: "internal",
        label: "Logout",
        route: "/",
        exec: () => {
          localStorage.clear();
        },
        color: "white",
      }}
    >
      <Grid item xs={12} lg={7} xl={12}>
        <Links />
      </Grid>
      <Footer />
    </BasicLayout>
  );
}

function CheckLogin() {
  const isLogged = checkExpiredToken();
  const location = useLocation();

  return isLogged ? (
    <ListLinks />
  ) : (
    <Redirect
      to={
        "/login?redirect=" +
        location.pathname +
        (location.search !== "" ? `${location.search}` : "")
      }
    />
  );
}

export default CheckLogin;
