import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";

import LoadingSVG from "examples/Icons/Loading";

function Loading() {
  return (
    <Card sx={{ padding: "17px" }}>
      <VuiBox
        height="3.4rem"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LoadingSVG />
      </VuiBox>
    </Card>
  );
}

export default Loading;
