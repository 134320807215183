import { useState, useEffect } from "react";

import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import MiniLoadingCard from "examples/Cards/Loading";

// React icons
import { IoPrintSharp } from "react-icons/io5";

// Service Axios
import api from "services/api";

function MiniCardPrinters() {
  const [refreshInterval, setRefreshInterval] = useState(60000);
  const [loading, setLoading] = useState(true);
  const [countPrintersOffLines, setCountPrintersOfflines] = useState(0);
  const [countPrintersOnLines, setCountPrintersOnlines] = useState(0);
  const [countAllPrinters, setCountAllPrinters] = useState(0);

  async function loadInfoDashAdmin() {
    setLoading(true);
    const dataPrint = [];
    const resPrint = await api.get("printers");

    resPrint.data.map((x) => {
      if (x.enabled === 1) {
        dataPrint.push({ status: x.status === 1 });
      }
    });

    const printersTotal = dataPrint.length + 1;
    const printersOffline = dataPrint.filter((x) => !x.status).length;
    const printersOnline = (printersOffline / printersTotal) * 100;

    setCountAllPrinters(printersTotal);
    setCountPrintersOfflines(printersOffline);
    setCountPrintersOnlines(printersOnline.toFixed(2));

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  useEffect(() => {
    loadInfoDashAdmin();
  }, [false]);

  useEffect(() => {
    if (refreshInterval && refreshInterval > 0) {
      const interval = setInterval(loadInfoDashAdmin, refreshInterval);
      return () => clearInterval(interval);
    }
  }, [refreshInterval]);

  if (loading) {
    return <MiniLoadingCard />;
  } else {
    return (
      <MiniStatisticsCard
        title={{ text: "Impressoras" }}
        count={countAllPrinters}
        percentage={{
          color: countPrintersOffLines > 0 ? "error" : "success",
          text:
            countPrintersOffLines > 0
              ? `${countPrintersOnLines}% Offline (${countPrintersOffLines})`
              : `100% Online`,
        }}
        icon={{
          color: countPrintersOffLines > 0 ? "error" : "success",
          component: <IoPrintSharp size="24px" color="white" />,
        }}
      />
    );
  }
}

export default MiniCardPrinters;
