import React from "react";
import { Card, Icon } from "@mui/material";

import SecurityDevice from "./components/DashSecurityDevice";

function LinkStatus() {
  return (
    <Card sx={{ backgroundColor: "green" }}>
      <SecurityDevice />
    </Card>
  );
}

export default LinkStatus;
