import styled, { css } from "styled-components";

import themes from "assets/theme/base/colors";

const useResolveColorTheme = ({ light, dark }) => {
  return dark;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  input {
    flex: 1;
    /* height: 100%; */
    /* height: 40px; */
    color: ${themes.inputColors.backgroundColor} !important;
    border: 0;
    font-weight: 400;
    font-size: 0.75rem !important;
    /* font-family: "Montserrat", sans-serif; */
    /* border-radius: 0.75rem; */
  }

  label {
    /* margin-bottom: 0.375rem; */
    /* padding-left: 0.125rem; */
    font-size: 0.75rem;
    color: ${() => useResolveColorTheme({ light: themes.grey[500], dark: themes.text.focus })};
  }

  .select__control {
    background-color: #0f1535 !important;
    background: "#7A7D8B";
  }

  .select__value-container {
    background-color: #0f1535 !important;
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
    height: 40px;
  }

  .select__indicators {
    background-color: #0f1535 !important;
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }

  .select__placeholder,
  .select__single-value {
    font-size: 0.9rem;
    color: ${() => useResolveColorTheme({ light: themes.grey[500], dark: themes.text.focus })};
  }

  .select__menu .select__menu-list {
    background-color: ${() =>
      useResolveColorTheme({ light: themes.text.focus, dark: themes.inputColors.backgroundColor })};
    color: ${() => useResolveColorTheme({ light: themes.grey[500], dark: themes.text.focus })};
    border-radius: 0.75rem;
    border: 0.094rem solid
      ${() => useResolveColorTheme({ light: themes.grey[500], dark: themes.text.focus })};
    padding: 0.3rem 0.5rem;
  }

  label,
  .select__placeholder,
  .select__option,
  .select__single-value {
    /* font-family: "Montserrat", sans-serif; */
    /* margin-bottom: 0.375rem; */
    padding-left: 0.125rem;
    font-weight: 400;
  }

  .select {
    /* 
    height: 2.75rem; 
    */

    border-radius: 0.75rem;
    border: 0.5px solid ${themes.grey[600]};

    border-width: 0.094rem;
    transition: all 0.2s;

    /* &:hover {
      border-color: ${(props) => props.theme.primary};
      border-width: 0.1rem;
      box-shadow: 0 0 0.325rem ${themes.inputColors.boxShadow};
    } */

    li {
      padding-left: 0.125rem;
    }

    > div {
      border: 0;
      box-shadow: none;
      height: 40px;
      border-radius: 0.75rem;
    }
  }

  ${(props) =>
    props.isFocused &&
    css`
      .select {
        border-color: ${props.theme.primary};
        border-width: 0.1rem;
        box-shadow: 0 0 0 0.125rem ${themes.inputColors.boxShadow};
      }

      label {
        color: ${props.theme.primary};
        /* margin-bottom: 0.374rem; */
      }

      svg {
        path {
          fill: ${props.theme.primary};
        }
      }
    `}
`;
