import React, { useState, useEffect } from "react";

import { IoEllipsisVerticalSharp } from "react-icons/io5";
import { experimentalStyled as styled } from "@mui/material/styles";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiProgress from "components/VuiProgress";
import VuiButton from "components/VuiButton";
import VuiLabel from "components/VuiLabel";
import VuiModal from "components/VuiModal";
import colors from "assets/theme/base/colors";

import moment from "moment";

import api from "services/api";

const stageLabel = [
  { label: "Operacional", color: "success" },
  { label: "Alerta 1", color: "alert" },
  { label: "Alerta 2", color: "warning" },
  { label: "Crítica", color: "error" },
];

const statusOccurrence = [
  "Aberto - Aguardando informações",
  "Entregue via e-mail. Aguardando informações",
  "Entregue via whatsapp",
  "Entregue via telegram",
  "Aguardando Manutenção",
  "Encerrado",
];

const CardInfoLink = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.black.modal,
  color: theme.palette.text.secondary,
  borderRadius: 10,
}));

function SLIOccurrence(d1, d2) {
  // console.log(">>> SLIOccurrence", d1, d2);
  const ms = moment(d1, "DD/MM/YYYY HH:mm:ss").diff(moment(d2, "DD/MM/YYYY HH:mm:ss"));
  const d = moment.duration(ms);
  return Math.floor(d.asHours()) + "h" + moment.utc(ms).format(" mm") + "m";
}

function InfoOccurrence({ item, onClose }) {
  if (!item.currentOccurrence) return null;
  return (
    <VuiBox
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "40%",
        // height: "40%",
        bgcolor: colors.grey[600],
        border: "1px solid #000",
        boxShadow: 24,
        borderRadius: 2,
        p: 2,
      }}
      onClick={() => {
        onClose;
      }}
    >
      {item && (
        <>
          <VuiLabel
            title={`${item.initials} - ${item.unit?.name}`}
            // colorTitle="black"
            fontWeightTitle={"bold"}
            sizeTitle={16}
          />

          <VuiLabel />

          <VuiLabel
            title="Ocorrência ID:"
            // colorTitle="black"
            sizeTitle={12}
            subtitle={item.currentOccurrence.id}
            openLinkClick={`/occurrenceview?occurrence=${item.currentOccurrence.id}`}
            colorSubTitle={"light"}
            fontWeightSubTitle="bold"
            sizeSubTitle={13}
          />
          <VuiLabel
            title="Data da Ocorrência:"
            // colorTitle="black"
            sizeTitle={12}
            subtitle={moment(item.currentOccurrence.createdAt).format("DD/MM/YYYY HH:mm:ss")}
            colorSubTitle={"light"}
            fontWeightSubTitle="bold"
            sizeSubTitle={13}
          />
          <VuiLabel
            title="Status da Ocorrência:"
            // colorTitle="black"
            sizeTitle={12}
            subtitle={statusOccurrence[item.currentOccurrence.status]}
            colorSubTitle={"light"}
            fontWeightSubTitle="bold"
            sizeSubTitle={13}
          />
          <VuiLabel />
          <VuiLabel
            title="SLA:"
            // colorTitle="black"
            sizeTitle={12}
            subtitle={"4 horas capital e 8 horas interior"}
            colorSubTitle={"light"}
            fontWeightSubTitle="bold"
            sizeSubTitle={13}
          />
          <VuiLabel
            title="Tempo da ocorrência:"
            // colorTitle="black"
            sizeTitle={12}
            subtitle={SLIOccurrence(
              moment().format("DD/MM/YYYY HH:mm:ss"),
              moment(item.currentOccurrence.createdAt).format("DD/MM/YYYY HH:mm:ss")
            )}
            colorSubTitle={"light"}
            fontWeightSubTitle="bold"
            sizeSubTitle={13}
          />

          {item.currentOccurrence.occurrenceStatus === 0 && (
            <>
              <VuiLabel />
              <VuiLabel title="Informações da Operadora" fontWeightTitle="bold" />
              {/* <VuiLabel /> */}

              <VuiLabel
                title="Data e Hora:"
                // colorTitle="black"
                sizeTitle={12}
                subtitle={moment(item.currentOccurrence.occurrenceDate).format(
                  "DD/MM/YYYY HH:mm:ss"
                )}
                colorSubTitle={"light"}
                fontWeightSubTitle="bold"
                sizeSubTitle={13}
              />
              <VuiLabel
                title="Status:"
                // colorTitle="black"
                sizeTitle={12}
                subtitle={statusOccurrence[item.currentOccurrence.occurrenceStatus]}
                colorSubTitle={"light"}
                fontWeightSubTitle="bold"
                sizeSubTitle={13}
              />
              <VuiLabel
                title="Operador:"
                // colorTitle="black"
                sizeTitle={12}
                subtitle={item.currentOccurrence.occurrenceOperatorName}
                colorSubTitle={"light"}
                fontWeightSubTitle="bold"
                sizeSubTitle={13}
              />
              <VuiLabel
                title="Número de Protocolo:"
                // colorTitle="black"
                sizeTitle={12}
                subtitle={item.currentOccurrence.occurrenceProtocolNumber}
                colorSubTitle={"light"}
                fontWeightSubTitle="bold"
                sizeSubTitle={13}
              />
              <VuiLabel
                title="Número Chamado Técnico:"
                // colorTitle="black"
                sizeTitle={12}
                subtitle={item.currentOccurrence.occurrenceCalledTechnician}
                colorSubTitle={"light"}
                fontWeightSubTitle="bold"
                sizeSubTitle={13}
              />
              <VuiLabel
                title="Motivo do suporte:"
                // colorTitle="black"
                sizeTitle={12}
                subtitle={item.currentOccurrence.occurrenceComments}
                colorSubTitle={"light"}
                fontWeightSubTitle="bold"
                sizeSubTitle={13}
              />
            </>
          )}
          <VuiLabel />
          <VuiLabel />
          <Grid container spacing="18px">
            <Grid item xs={12} lg={6} xl={9}></Grid>
            <Grid item xs={12} lg={6} xl={3}>
              <div
                onClick={() => {
                  onClose();
                }}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: colors.success.main,
                  color: colors.white.main,
                  fontSize: 12,
                  fontWeight: "bold",
                  borderRadius: 5,
                  height: 32,
                  cursor: "pointer",
                }}
              >
                Fechar
              </div>
            </Grid>
          </Grid>
        </>
      )}
    </VuiBox>
  );
}

function VuiInfoItemLink({ item, index }) {
  const [isOpenModal, setIsOpenModal] = useState(false);

  if (item) {
    return (
      <CardInfoLink>
        <VuiModal
          isOpen={isOpenModal}
          onClose={() => {
            setIsOpenModal(!isOpenModal);
          }}
        >
          <InfoOccurrence
            item={item}
            onClose={() => {
              setIsOpenModal(!isOpenModal);
            }}
          />
        </VuiModal>

        <VuiBox
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 10,
          }}
        >
          <VuiTypography color="white" variant="button" fontWeight="bold">
            {item.initials}
          </VuiTypography>
          <VuiTypography
            color="white"
            variant="button"
            fontWeight="regular"
            noWrap={true}
            ml="10px"
            mr="10px"
          >
            {item.unit?.name}
          </VuiTypography>
          <VuiTypography color="white" variant="lg">
            <IoEllipsisVerticalSharp />
          </VuiTypography>
        </VuiBox>

        <VuiBox
          style={{
            display: "flex",
            flexDirection: "column",
            alignSelf: "flex-start",
            marginLeft: 10,
            marginRight: 10,
          }}
        >
          <VuiBox display="flex" flexDirection="row" alignItems="center">
            <VuiBox
              style={{
                width: "100%",
              }}
            >
              <VuiLabel title="Endereço:" subtitle={item.unit?.address} />
              <VuiLabel title="Cidade:" subtitle={item.unit?.city} />
              <VuiLabel title="Contato:" subtitle={item.unit?.contact} />
              <VuiLabel
                title="Telefone:"
                subtitle={
                  item.unit.phone
                    ? item.unit.phone
                        .toString()
                        .replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, "($1) $2 $3-$4")
                    : ""
                }
              />
              <VuiLabel title="" />

              <VuiLabel
                title="Operadora:"
                subtitle={item.subCarrier ? `${item.carrier} (${item.subCarrier})` : item.carrier}
              />
              <VuiLabel
                title="IP Gateway:"
                subtitle={item.circuit ? `${item.ip} (${item.circuit.split("-")[0]})` : item.ip}
              />
              <VuiLabel
                title={`Situação:`}
                colorSubTitle={`${stageLabel[item.stage].color}`}
                subtitle={`${stageLabel[item.stage].label}`}
                fontWeightSubTitle={item.status ? "regular" : "bold"}
              />

              {item.status ? (
                <VuiLabel
                  title="Latência:"
                  subtitle={`${isNaN(item.ttl) ? 0 : item.ttl} ms`}
                  colorSubTitle={
                    item.ttl >= 0 && item.ttl <= 50 && item.status
                      ? "online"
                      : item.ttl > 50 && item.ttl <= 100
                      ? "attention"
                      : "error"
                  }
                />
              ) : item.stage === 3 ? (
                <VuiLabel
                  title="ID Ocorrência:"
                  subtitle={item.lastOpeningNumber}
                  fontWeightSubTitle={item.status ? "regular" : "bold"}
                  onClick={() => {
                    console.log(isOpenModal);
                    setIsOpenModal(!isOpenModal);
                  }}
                  iconOnClick="info"
                />
              ) : null}

              <VuiBox
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  marginBottom: 20,
                }}
              >
                <VuiTypography
                  style={{
                    fontSize: 32,
                    fontWeight: "bold",
                    color: item.status ? colors.alert.online : colors.alert.offline,
                    textAlign: "center",
                  }}
                >
                  {item.status ? "Online" : "Offline"}
                </VuiTypography>
                {/* <VuiProgress value={100} color={item.status ? "success" : "error"} label={false} /> */}
              </VuiBox>
            </VuiBox>
          </VuiBox>
          <VuiBox mt="20px" mb="20px" flexDirection="row">
            <VuiTypography
              color="text"
              style={{
                fontSize: 11,
                fontWeight: "100",
                textAlign: "right",
              }}
            >
              {item.status ? "Última atualização" : "Falha da conexão desde"} {item.updatedAt}
            </VuiTypography>
            {!item.status ? (
              <VuiTypography
                color="text"
                style={{
                  fontSize: 11,
                  fontWeight: "100",
                  textAlign: "right",
                }}
              >
                Última atualização {item.lastUpdatedAt}
              </VuiTypography>
            ) : (
              <VuiLabel />
            )}
          </VuiBox>
        </VuiBox>
      </CardInfoLink>
    );
  } else {
    return <CardInfoLink />;
  }
}

export default VuiInfoItemLink;
