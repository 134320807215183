import React, { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Service Axios
import api from "services/api";

// Dashboard layout components
import WelcomeMark from "./components/WelcomeMark";
import SystemLinks from "./components/SystemLinks";
import BannerSystem from "./components/BannerSystem";

function Dashboard() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  async function loadBanner() {
    const resBanner = await api.get("banner");
    setData(resBanner.data.data.filter((x) => x.disabled === 1));
    setLoading(false);
  }

  useEffect(() => {
    loadBanner();
  }, [false]);

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <VuiBox>
        {data && data.length > 0 && (
          <VuiBox mb={3}>
            <Grid container>
              <Grid item xs={12} lg={12} xl={12}>
                <BannerSystem data={data} />
              </Grid>
            </Grid>
          </VuiBox>
        )}
        <VuiBox mb={3}>
          <Grid container spacing="18px">
            <Grid item xs={12} lg={6} xl={6}>
              <WelcomeMark />
            </Grid>
            <Grid item xs={12} lg={6} xl={6}>
              <SystemLinks />
            </Grid>
          </Grid>
        </VuiBox>

        <VuiBox mb={3}>
          <Grid container spacing="18px">
            <Grid item xs={12} lg={6} xl={4}>
              <VuiBox mb={3} height={330} />
            </Grid>
            <Grid item xs={12} lg={6} xl={4}>
              <VuiBox mb={3} height={330} />
            </Grid>
            <Grid item xs={12} lg={6} xl={4}></Grid>
          </Grid>
        </VuiBox>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
