import { useState, useEffect } from "react";

// google-maps
import GoogleMapReact from "google-map-react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiLabel from "components/VuiLabel";

// Vision UI Dashboard React example components
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";

import Markers from "./Markers";
import InfoMarkerModal from "./InfoMarker";

import api from "services/api";
import moment from "moment";

import colors from "assets/theme/base/colors";

const KEY_MAPS = "AIzaSyA5y0onE92_1-LJVseKteBV9dzlUYKd4yc";

const defaultProps = {
  center: {
    lat: -4.5800264,
    lng: -44.5297029,
  },
  zoom: 6.8,
};

const stageLabel = [
  { label: "Operacional", color: "success" },
  { label: "Alerta 1", color: "alert" },
  { label: "Alerta 2", color: "warning" },
  { label: "Crítica", color: "error" },
];

const distanceToMouse = (pt, mp) => {
  if (pt && mp) {
    // return distance between the marker and mouse pointer
    return Math.sqrt((pt.x - mp.x) * (pt.x - mp.x) + (pt.y - mp.y) * (pt.y - mp.y));
  }
};

function Maps() {
  const [points, setPoints] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [refreshInterval, setRefreshInterval] = useState(60000);
  const [countOffLines, setCountOfflines] = useState(0);
  const [countOnLines, setCountOnlines] = useState(0);
  const [countAllUnits, setCountAllUnits] = useState(0);
  const [infoPoints, setInfoPoints] = useState(null);
  const [isShowInfo, setIsShowInfo] = useState(false);

  async function loadLinks() {
    const data = [];
    const res = await api.get("links");

    res.data.map((x) => {
      console.log(">>> Item:", x);
      data.push({
        ...x,
        ip: x.gateway,
        status: x.laststatus,
        stage: x.hops,
        ttl: x.max,
        updatedAt: x.laststatus
          ? moment().format("DD/MM/YYYY HH:mm:ss")
          : moment(x.updatedAt).format("DD/MM/YYYY HH:mm:ss"),
        lastUpdatedAt: moment().format("DD/MM/YYYY HH:mm:ss"),
      });
    });

    data
      .sort((a, b) => {
        if (a.ttl > b.ttl) {
          return -1;
        }
        if (a.ttl < b.ttl) {
          return 1;
        }
        return 0;
      })
      .sort((a, b) => Number(a.status) - Number(b.status));

    setCountOfflines(data.filter((x) => !x.status).length);
    setCountOnlines(data.filter((x) => x.status).length);
    setCountAllUnits(data.length);
    setPoints(data);
    setLoading(false);
  }

  useEffect(() => {
    loadLinks();
  }, [false]);

  useEffect(() => {
    if (refreshInterval && refreshInterval > 0) {
      const interval = setInterval(loadLinks, refreshInterval);
      return () => clearInterval(interval);
    }
  }, [refreshInterval]);

  return (
    <div
      style={{
        height: "80vh",
        width: "100%",
      }}
    >
      <GoogleMapReact
        bootstrapURLKeys={{ key: KEY_MAPS }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        distanceToMouse={distanceToMouse}
      >
        {points.map((item, index) => {
          return (
            <Markers
              key={item.index}
              lat={item.unit.lat}
              lng={item.unit.lng}
              name={item.unit.initials}
              enabled={item.enabled}
              status={item.status}
              onClick={() => {
                setInfoPoints(item);
                setIsShowInfo(true);
              }}
            />
          );
        })}
        <InfoMarkerModal
          isOpen={isShowInfo}
          onClose={() => {
            setInfoPoints(null);
            setIsShowInfo(false);
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 500,
              bgcolor: colors.black.modal,
              border: "1px solid #000",
              boxShadow: 24,
              borderRadius: 2,
              p: 2,
            }}
            onClick={() => {
              setInfoPoints(null);
              setIsShowInfo(false);
            }}
          >
            {infoPoints && (
              <>
                <VuiLabel
                  title={`${infoPoints.initials} - ${infoPoints.units.name}`}
                  fontWeightTitle={"bold"}
                  sizeTitle={16}
                />
                <VuiLabel
                  title="Endereço:"
                  sizeTitle={12}
                  subtitle={infoPoints.units.address}
                  colorSubTitle={"text"}
                  sizeSubTitle={12}
                />
                <VuiLabel
                  title="Cidade:"
                  sizeTitle={12}
                  subtitle={infoPoints.units.city}
                  colorSubTitle={"text"}
                  sizeSubTitle={12}
                />
                <VuiLabel
                  title="Contato:"
                  sizeTitle={12}
                  subtitle={infoPoints.units.contact}
                  colorSubTitle={"text"}
                  sizeSubTitle={12}
                />
                <VuiLabel
                  title="Telefone:"
                  sizeTitle={12}
                  subtitle={infoPoints.units.phone
                    .toString()
                    .replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, "($1) $2 $3-$4")}
                  colorSubTitle={"text"}
                  sizeSubTitle={12}
                />

                {infoPoints.enabled ? (
                  <>
                    <VuiLabel title="" sizeTitle={12} />
                    <VuiLabel
                      title="Operadora:"
                      sizeTitle={12}
                      subtitle={
                        infoPoints.subCarrier
                          ? `${infoPoints.carrier} (${infoPoints.subCarrier})`
                          : infoPoints.carrier
                      }
                      colorSubTitle={"text"}
                      sizeSubTitle={12}
                    />
                    <VuiLabel
                      title="IP Gateway:"
                      sizeTitle={12}
                      subtitle={
                        infoPoints.circuit
                          ? `${infoPoints.ip} (${infoPoints.circuit.split("-")[0]})`
                          : infoPoints.ip
                      }
                      colorSubTitle={"text"}
                      sizeSubTitle={12}
                    />
                    <VuiLabel
                      title="Situação:"
                      sizeTitle={12}
                      subtitle={`${stageLabel[infoPoints.status ? infoPoints.stage : 3].label}`}
                      colorSubTitle={infoPoints.status ? "text" : "error"}
                      sizeSubTitle={12}
                      fontWeightSubTitle="bold"
                    />
                    <VuiLabel
                      title="Latência:"
                      sizeTitle={12}
                      subtitle={`${isNaN(infoPoints.ttl) ? 0 : infoPoints.ttl} ms`}
                      colorSubTitle={
                        infoPoints.ttl >= 0 && infoPoints.ttl <= 50 && infoPoints.status
                          ? "online"
                          : infoPoints.ttl > 50 && infoPoints.ttl <= 100
                          ? "attention"
                          : "error"
                      }
                      sizeSubTitle={12}
                      fontWeightSubTitle="bold"
                    />
                    <VuiLabel title="" sizeTitle={12} />
                    <VuiLabel
                      title={infoPoints.status ? "Última atualização" : "Falha da conexão desde"}
                      subtitle={infoPoints.updatedAt}
                    />
                  </>
                ) : (
                  <>
                    <VuiLabel title="" sizeTitle={12} />
                    <VuiLabel
                      title="Situação:"
                      sizeTitle={12}
                      subtitle="Unidade não monitorada"
                      colorSubTitle={"text"}
                      sizeSubTitle={12}
                    />
                    <VuiLabel title="" sizeTitle={12} />
                  </>
                )}
              </>
            )}
          </Box>
        </InfoMarkerModal>
      </GoogleMapReact>
    </div>
  );
}

function ShowMaps() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox sx={{ height: 900 }}>
        <VuiBox>
          <Grid item xs={12} lg={7} xl={12}>
            <Maps />
          </Grid>
        </VuiBox>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ShowMaps;
