import React, { useState, useEffect } from "react";
import { Card } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import moment from "moment";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

import VuiProgress from "components/VuiProgress";
import VuiOpenLink from "components/VuiOpenLink";
import VuiBox from "components/VuiBox";
import VuiLoading from "components/VuiLoading";
import VuiLabel from "components/VuiLabel";
import VuiInfoItemPrinter from "components/VuiInfoItemPrinter";

import api from "services/api";
import colors from "assets/theme/base/colors";

// Function randomInt(min, max)
// min and max included
function randomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const padLeft = (num, places) => String(num).padStart(places, "0");

function PrinterStatics() {
  const [refreshInterval, setRefreshInterval] = useState(60000);
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [countOffLines, setCountOfflines] = useState(0);
  const [countOnLines, setCountOnlines] = useState(0);
  const [countAllUnits, setCountAllUnits] = useState(0);

  async function loadPrinters() {
    setLoading(true);
    const res = await api.get("printers");

    const data = [];
    res.data.map((x) => {
      if (x.enabled === 1) {
        data.push({
          initials: x.initials,
          name: x.name,
          factoryPrinter: x.factoryPrinter,
          modelPrinter: x.modelPrinter,
          serialPrinter: x.serialPrinter,
          isColorPrinter: x.isColorPrinter,
          countPageFranchise: x.countPageFranchise,
          currentBilling: x.currentBilling,
          countPageOverPlus: x.currentBilling - x.countPageFranchise,
          percentageBilling: ((x.currentBilling / x.countPageFranchise) * 100).toFixed(2),
          location: x.location,
          ip: x.ip,
          status: x.status === 1,
          countPage: x.countPage || 0,
          maxLevelToner: x.maxLevelToner || 0,
          currentLevelToner: x.currentLevelToner || 0,
          calcValue: ((x.currentLevelToner / x.maxLevelToner) * 100).toFixed(0),
          order: padLeft(((x.currentLevelToner / x.maxLevelToner) * 100).toFixed(0), 3),
          updatedAt: moment(x.updatedAt).format("DD/MM/YYYY HH:mm:ss"),
        });
      }
    });
    const roundPrinter = randomInt(0, data.length - 1);

    setCountOfflines(data.filter((x) => !x.status).length);
    setCountOnlines(data.filter((x) => x.status).length + 1);
    setCountAllUnits(data.length + 1);
    setRows(data[roundPrinter]);
    setInterval(() => {
      setLoading(false);
    }, 1000);
  }

  useEffect(() => {
    loadPrinters();
  }, [false]);

  useEffect(() => {
    if (refreshInterval && refreshInterval > 0) {
      const interval = setInterval(loadPrinters, refreshInterval);
      return () => clearInterval(interval);
    }
  }, [refreshInterval]);

  return (
    <>
      {loading ? (
        <VuiLoading />
      ) : (
        <>
          <div
            style={{
              display: "grid",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              marginBottom: 20,
            }}
          >
            <VuiOpenLink
              title={`${countAllUnits} Impressoras`}
              url="printers"
              colorTitle="warning"
              fontWeight="bold"
              fontSize={24}
            />
            <VuiBox display="flex" flexDirection="row" justifyContent="flex-end">
              {countOffLines > 0 ? (
                <VuiLabel
                  title={`${
                    countOffLines > 0 && countOffLines < countAllUnits
                      ? ((countOffLines / countAllUnits) * 100).toFixed(2)
                      : 100
                  }%`}
                  colorTitle={"warning"}
                  sizeTitle={18}
                  //
                  subtitle="&nbsp;Offline"
                  colorSubTitle="error"
                  sizeSubTitle={18}
                />
              ) : (
                <>
                  <div style={{ textAlign: "right", color: colors.success.focus }}>100%</div>
                  <div
                    style={{ fontWeight: "bold", textAlign: "right", color: colors.success.main }}
                  >
                    Online
                  </div>
                </>
              )}
            </VuiBox>
            <VuiProgress
              value={(countOffLines / countAllUnits) * 100}
              color="error"
              label={false}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {countOffLines > 0 ? (
              <VuiBox
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <NotificationsActiveIcon
                  fontSize="large"
                  color="error"
                  sx={{ marginRight: "15px" }}
                />
                <VuiOpenLink
                  title={`${countOffLines} ${
                    countOffLines > 1 ? "impressoras" : "impressora"
                  } Offine de ${countAllUnits} Ativas`}
                  colorTitle={"error"}
                  url="printers?search=offline"
                />
              </VuiBox>
            ) : null}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              marginBottom: 20,
            }}
          >
            {countOnLines > 0 ? (
              <VuiOpenLink
                title={`${countOnLines} Impressoras Online`}
                colorTitle={"success"}
                url="printers?search=online"
              />
            ) : null}
          </div>
          <Box sx={{ flexGrow: 2 }}>
            <VuiInfoItemPrinter item={rows} index={0} />
          </Box>
        </>
      )}
    </>
  );
}

export default PrinterStatics;
