import React, { InputHTMLAttributes, useCallback, forwardRef, useState } from "react";
import InputMask, { ReactInputMask } from "react-input-mask";

import VuiInputRoot from "./VuiInputRoot";

const VuiInputMask = forwardRef(
  (
    {
      name,
      placeholder,
      type = "text",
      error = "",
      disabled = false,
      label,
      icon: Icon,
      size,
      success,
      action = undefined,
      onCustomChange,
      onCustomPaste,
      onBlur,
      subLabel,
      mask = [],
      ...rest
    },
    ...ref
  ) => {
    const [state, setState] = useState({
      isFocused: false,
      hasError: false,
      mask:
        mask === "CPF" || mask === "cpf"
          ? "999.999.999-99"
          : mask === "PHONE" || mask === "phone"
          ? "(99) 99999-9999"
          : mask === "CEP" || mask === "cep"
          ? "99999-999"
          : mask === "CURRENCY" || mask === "currency"
          ? ""
          : "",
    });

    return (
      <InputMask
        data-testid="input"
        mask={state.mask}
        placeholder={placeholder}
        id={name}
        name={name}
        type={type}
        onFocus={() => {
          setState({ ...state, isFocused: true, hasError: false });
        }}
        onChangeCapture={onCustomChange}
        onBlur={(event) => {
          setState({ ...state, isFocused: false, hasError: true });
          onBlur && onBlur(event);
        }}
        autoComplete="chrome-off"
        {...rest}
        ref={ref}
      >
        {(props) => {
          return (
            <VuiInputRoot
              placeholder={placeholder}
              ref={ref}
              ownerState={{ size, error, success, disabled }}
            />
          );
        }}
      </InputMask>
    );
  }
);

export default VuiInputMask;
