// @mui material components
import Grid from "@mui/material/Grid";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";

// Vision UI Dashboard React components
import MasterCard from "examples/Cards/MasterCard";
// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Billing page components
import Branches from "./components/Branches";

function ListLinks() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox mt={0} sx={{ height: 900 }}>
        <VuiBox mb={0}>
          <Grid item xs={12} lg={7} xl={12}>
            <Branches />
          </Grid>
        </VuiBox>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ListLinks;
