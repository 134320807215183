import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import moment from "moment";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiProgress from "components/VuiProgress";
import VuiInfoItemLink from "components/VuiInfoItemLink";
import VuiLabel from "components/VuiLabel";
import VuiLoading from "components/VuiLoading";

import api from "services/api";

import useWindowDimensions from "hooks/useWindowDimensions";

import colors from "assets/theme/base/colors";

function Links() {
  const { height, width } = useWindowDimensions();
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [refreshInterval, setRefreshInterval] = useState(60000);
  const [countOffLines, setCountOfflines] = useState(0);
  const [countOnLines, setCountOnlines] = useState(0);
  const [countAllUnits, setCountAllUnits] = useState(0);
  const { search } = useLocation();
  const actionCommand = search.replace("?", "").split("=")[0];
  const actionExec = search.replace("?", "").split("=")[1];

  async function loadLinks() {
    const data = [];
    const res = await api.get("links");

    res.data.map((x) => {
      if (x.enabled) {
        data.push({
          ...x,
          ip: x.gateway,
          status: x.laststatus,
          stage: x.hops,
          ttl: x.max,
          updatedAt: x.laststatus
            ? moment().format("DD/MM/YYYY HH:mm:ss")
            : moment(x.updatedAt).format("DD/MM/YYYY HH:mm:ss"),
          lastUpdatedAt: moment().format("DD/MM/YYYY HH:mm:ss"),
        });
      }
    });

    data
      .sort((a, b) => {
        if (a.ttl > b.ttl) {
          return -1;
        }
        if (a.ttl < b.ttl) {
          return 1;
        }
        return 0;
      })
      .sort((a, b) => Number(a.status) - Number(b.status));

    const countOfflines = data.filter((x) => !x.status).length;
    const countOnlines = data.filter((x) => x.status).length;
    const countAllLinks = data.length;

    setCountOfflines(countOfflines);
    setCountOnlines(countOnlines);
    setCountAllUnits(countAllLinks);

    console.log(">>> data:", data);
    console.log(">>> Search:actionCommand:", actionCommand, "- Search:actionExec:", actionExec);

    if (actionCommand === "search" && actionExec === "offline") {
      setRows(data.filter((x) => !x.status));
    } else if (actionCommand === "search" && actionExec === "online") {
      setRows(data.filter((x) => x.status));
    } else if (
      actionCommand === "search" &&
      (actionExec !== "online" || actionExec !== "offline")
    ) {
      if (actionExec !== "") {
        if (actionExec.search("%") > 0) {
          console.log(">>> actionExec:", actionExec);
          const newData = data
            .filter(
              (x) =>
                x.initials.substring(0, actionExec.length - 1).toUpperCase() ===
                actionExec.substring(0, actionExec.length - 1).toUpperCase()
            )
            .sort((a, b) => {
              if (a.ip > b.ip) {
                return 1;
              }
              if (a.ip < b.ip) {
                return -1;
              }
              return 0;
            });

          console.log(">>> newData:", newData);

          setRows(newData);
        } else {
          setRows(data.filter((x) => x.initials === actionExec.toUpperCase()));
        }
      } else {
        setRows(data);
      }
    } else {
      setRows(data);
    }

    setLoading(false);
  }

  useEffect(() => {
    loadLinks();
  }, [false]);

  useEffect(() => {
    if (refreshInterval && refreshInterval > 0) {
      const interval = setInterval(loadLinks, refreshInterval);
      return () => clearInterval(interval);
    }
  }, [refreshInterval]);

  return (
    <Card
      sx={{
        height: "100% !important",
      }}
    >
      <VuiBox
        sx={{
          "& th": {
            borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
              `${borderWidth[1]} solid ${grey[700]}`,
          },
          "& .MuiTableRow-root:not(:last-child)": {
            "& td": {
              borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                `${borderWidth[1]} solid ${grey[700]}`,
            },
          },
        }}
      >
        {loading ? (
          <VuiLoading />
        ) : (
          <div>
            <div
              style={{
                display: "grid",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                fontSize: 24,
                marginBottom: 20,
              }}
            >
              <div style={{ textAlign: "right", color: colors.warning.focus }}>
                Total de Unidades {countAllUnits} ativas
              </div>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                {countOffLines > 0 ? (
                  <VuiLabel
                    title={`${
                      countOffLines > 0 && countOffLines < countAllUnits
                        ? ((countOffLines / countAllUnits) * 100).toFixed(2)
                        : 100
                    }%`}
                    colorTitle={"warning"}
                    sizeTitle={18}
                    subtitle="&nbsp;Offline"
                    colorSubTitle="error"
                    sizeSubTitle={18}
                  />
                ) : (
                  <>
                    <div style={{ textAlign: "right", color: colors.success.focus }}>100%</div>
                    <div
                      style={{ fontWeight: "bold", textAlign: "right", color: colors.success.main }}
                    >
                      Online
                    </div>
                  </>
                )}
              </div>
              <VuiProgress
                value={(countOffLines / countAllUnits) * 100}
                color="error"
                label={false}
              />
            </div>
            <Box sx={{ flexGrow: 2 }}>
              <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 3, sm: 6, md: 18 }}>
                {rows.map((item, index) => {
                  return (
                    <Grid item xs={6} sm={6} md={6} key={index}>
                      <VuiInfoItemLink item={item} index={index} />
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </div>
        )}
      </VuiBox>
    </Card>
  );
}

export default Links;
