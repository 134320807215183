import React, { useState, useEffect } from "react";

import { v4 as uuidv4 } from "uuid";

import Grid from "@mui/material/Grid";
import { Card } from "@mui/material";

import VuiTypography from "components/VuiTypography";

import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import VuiInput from "components/VuiInput";
import VuiLabel from "components/VuiLabel";
import VuiSwitch from "components/VuiSwitch";
import VuiDatePicker from "components/VuiDatePickers";

import dayjs from "dayjs";

import imgPhotoDefault from "assets/images/background-photo.jpeg";

const ACTION_NONE = 0;
const ACTION_ADD = 1;
const ACTION_EDIT = 2;
const ACTION_DELETE = 3;

import { backEndUrl } from "services/api";

import axios from "axios";

function BannerAction({ action, data, onClick }) {
  const [states, setStates] = useState({
    index: 0,
    id: 0,
    uuid: uuidv4().replace(/-/g, ""),
    caption: "",
    image: imgPhotoDefault,
    expiresIn: dayjs().add(10, "day"),
    file: "https://api.monitor.seap.ma.gov.br/images/" + imgPhotoDefault.split("/")[3],
    disabled: false,
  });

  const [statesNoChance, setStatesNoChance] = useState(null);

  const [isEnabledButtonSave, setIsEnabledButtonSave] = useState(true);
  const [publicationStatus, setPublicationStatus] = useState(true);

  const handleSetPublicationStatus = () => setPublicationStatus(!publicationStatus);

  const handleFileChange = (e) => {
    if (!e.target.files) {
      return;
    }
    const nameImage = e.target.files[0].name;
    const extImage = nameImage.substring(nameImage.lastIndexOf(".") + 1);
    const saveAsImage = states.uuid + "." + extImage;
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setStates({
          ...states,
          image: reader.result,
          file: "https://api.monitor.seap.ma.gov.br/images/" + saveAsImage,
        });
      }
    };
    reader.readAsDataURL(e.target.files[0]);

    handleUpload(e, e.target.files[0], e.target.files[0].name, saveAsImage);
  };

  const handleUpload = async (event, file, name, saveAsImage) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("file", file, name);

    try {
      const response = await axios({
        method: "post",
        url: backEndUrl + "upload",
        data: formData,
        headers: { "Content-Type": "multipart/form-data", filename: saveAsImage },
      });

      console.log(">>> saveAs", response.data.saveAs);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let item = states;

    if (action === ACTION_ADD) {
      setStatesNoChance(states);
    }

    if (action === ACTION_EDIT) {
      const currentDate = dayjs().format("DD/MM/YYYY");
      const expiredDate = new Date(data.expiresIn).toLocaleDateString();

      console.log(">>> ", currentDate, expiredDate, dayjs(data.expiresIn).format("DD/MM/YYYY"));
      item = {
        index: data.index,
        id: data.id,
        uuid: data.uuid,
        caption: data.caption,
        image: data.image,
        file: data.image,
        expiresIn: dayjs(data.expiresIn),
        disabled: data.disabled,
      };
      setStates(item);
      setStatesNoChance(item);
      setPublicationStatus(expiredDate < currentDate);
    }
  }, [false]);

  useEffect(() => {
    console.log(">>> states ...........:", states);
    console.log(">>> statesNoChance ...:", statesNoChance);
  }, [states]);

  return (
    <Card sx={{ width: "100%", justifyContent: "center", alignItems: "center" }}>
      <Grid container spacing="18px">
        <Grid item xs={12} lg={6} xl={3}>
          <VuiLabel title="ID:" />
          <VuiInput
            placeholder=""
            value={states.uuid}
            onChange={(e) => {
              console.log(">>> ID:", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} lg={6} xl={6}>
          <VuiLabel title="Titúlo da postagem:" />
          <VuiInput
            placeholder="Digite um titulo para a postagem"
            value={states.caption}
            onChange={(e) => {
              console.log(">>> Titulo", e.target.value);
              const newStates = {
                ...states,
                caption: e.target.value,
              };

              setStates(newStates);
            }}
          />
        </Grid>
        <Grid item xs={12} lg={6} xl={3}>
          <VuiLabel title="Data que Expira:" />
          <VuiDatePicker
            placeholder="Informe a data de termino da postagem..."
            value={states.expiresIn}
            onChange={(e) => {
              const dateNow = new Date().toLocaleDateString();
              const dateChance = new Date(e).toLocaleDateString();
              const newStates = {
                ...states,
                expiresIn: new Date(e).toLocaleDateString(),
              };
              setStates(newStates);
              setPublicationStatus(dateChance > dateNow);
            }}
          />
        </Grid>

        <Grid item xs={12} lg={6} xl={9}>
          <VuiLabel title="URL da imagem:" />
          <VuiInput placeholder="" value={states.file} />
        </Grid>

        {action === ACTION_ADD ? (
          <Grid item xs={12} lg={6} xl={3}>
            <VuiLabel title="Escolha um arquivo:" />
            <form onSubmit={handleUpload}>
              <input
                type="file"
                name="file-5[]"
                id="file-5"
                style={{ display: "none" }}
                onChange={(e) => handleFileChange(e)}
              />
              <label htmlFor="file-5">
                <figure style={{ marginTop: 5 }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 20 17"
                  >
                    <path
                      fill="#FFF"
                      d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"
                    />
                  </svg>
                </figure>
              </label>
            </form>
          </Grid>
        ) : (
          <Grid item xs={12} lg={6} xl={3}>
            <VuiLabel title="Status da Publicação:" />
            <VuiSwitch
              color="secondary"
              checked={publicationStatus}
              onChange={handleSetPublicationStatus}
            />
            <VuiTypography
              variant="caption"
              color="white"
              fontWeight="medium"
              onClick={handleSetPublicationStatus}
              sx={{ cursor: "pointer", userSelect: "none" }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;{publicationStatus ? "Habilitado" : "Desabilitado"}
            </VuiTypography>
          </Grid>
        )}

        {action === ACTION_ADD ? (
          <Grid item xs={12} lg={6} xl={4}>
            <div style={{ color: "#fff", fontSize: 14 }}>
              <VuiLabel title="Colaborador(a)," sizeTitle={20} fontWeightTitle={"bold"} />
              <p>
                <br />
                Informamos que a imagem deve ter os seguintes requisitos:
              </p>
              <p>
                <br />
                Lagura: 1600 x Altura: 900 pixel
              </p>
            </div>
          </Grid>
        ) : null}

        <Grid item xs={12} lg={6} xl={action === ACTION_ADD ? 8 : 12}>
          {states.image ? (
            <VuiBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ m: 1, border: 1, borderRadius: 4 }}
            >
              <img
                src={states.image}
                alt=""
                style={{ borderRadius: 15, width: "100%" }}
                // resizeMode="contain"
              />
            </VuiBox>
          ) : null}
        </Grid>

        <Grid item xs={12} lg={6} xl={8}></Grid>
        <Grid item xs={12} lg={6} xl={2}>
          <VuiButton
            color="secondary"
            onClick={() => {
              onClick(false);
            }}
            sx={{ height: 36, width: "100%" }}
          >
            Fechar
          </VuiButton>
        </Grid>
        <Grid item xs={12} lg={6} xl={2}>
          <VuiButton
            disabled={isEnabledButtonSave}
            color="secondary"
            onClick={() => {
              onClick(true);
            }}
            sx={{ height: 36, width: "100%" }}
          >
            Salvar
          </VuiButton>
        </Grid>
      </Grid>
      <VuiBox sx={{ mb: 3, height: 10, width: "100%" }} />
    </Card>
  );
}

export default BannerAction;
