import { useLocation, Redirect } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";

// Vision UI Dashboard React example components
import Footer from "examples/Footer";
import BasicLayout from "../components/BasicLayout";

import Printers from "./components/Printers";

function ListPrinters() {
  return (
    <BasicLayout gotoPage="/admin">
      <Printers />
      <Footer />
    </BasicLayout>
  );
}

function CheckLogin() {
  const location = useLocation();
  const isLoggedStorage = localStorage.getItem("logged");
  return isLoggedStorage ? (
    <ListPrinters />
  ) : (
    <Redirect
      to={
        "/login?redirect=" +
        location.pathname +
        (location.search !== "" ? `${location.search}` : "")
      }
    />
  );
}

export default CheckLogin;
