import { useState, useEffect } from "react";
import { useHistory, Redirect } from "react-router-dom";

// import CircularProgress from "@mui/joy/CircularProgress";
import { CircularProgress } from "@mui/material";

import { IoCheckmarkSharp } from "react-icons/io5";
import { IoCheckmarkDoneSharp } from "react-icons/io5";

import { Toaster, toast } from "react-hot-toast";
import Grid from "@mui/material/Grid";
import moment from "moment";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiLabel from "components/VuiLabel";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInputText";
import VuiInputMask from "components/VuiInputMask";
import VuiButton from "components/VuiButton";
import VuiButtonCustom from "components/VuiButtonCustom";
import VuiSwitch from "components/VuiSwitch";
import VuiSelect from "components/VuiSelect";
import VuiRadio from "components/VuiRadio";
import VuiOpenLink from "components/VuiOpenLink";
import VuiModal from "components/VuiModal";
import VuiLoading from "components/VuiLoading";
import VuiCircularWithValueLabel from "components/VuiCircularWithValueLabel";

import GradientBorder from "examples/GradientBorder";

// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Authentication layout components
import CoverLayout from "../components/CoverLayout";

// Helper
import { validateCPF, validateEmail } from "../../../helpers/utilities";

// Images
import bgSignIn from "assets/images/logos/logo-seap.png";

// Vision UI Dashboard React contexts
import { useVisionUIController, setUserLogged } from "context";

import api, { backEndUrl, frontEndUrl, backImgUrl } from "services/api";

import { Container, Content } from "./styles";

const PAGE_SUPPORT = "/support";

const notify = (message) =>
  toast.error(message, {
    style: {
      fontSize: 14,
      borderRadius: "5px",
      background: "#333",
      color: "#fff",
    },
  });

function FocusNextField(fieldName) {
  const nextfield = document.querySelector(`input[name=${fieldName}]`);
  nextfield.focus();
}

function CircularProgressThickness({ label, timeOut = 100, height = 600, onExec, onProgress }) {
  const [progress, setProgress] = useState(1);
  let msgLabel = "";

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        onProgress(prevProgress);
        return prevProgress >= 101 ? 1 : prevProgress + 1;
      });
    }, timeOut);
    return () => {
      clearInterval(timer);
    };
  }, []);

  if (progress === 100) {
    onExec();
  }

  const strLabel = label.filter((x) => {
    if (progress >= x.min && progress <= x.max) {
      return true;
    }
  });

  msgLabel = strLabel.length > 0 ? strLabel[0].msg : msgLabel;

  return (
    <div
      style={{ display: "flex", justifyContent: "center", alignItems: "center", height: height }}
    >
      <VuiCircularWithValueLabel label={msgLabel} value={progress} size="160px" thickness={0.5} />
    </div>
  );
}

function VuiRow() {
  return <div style={{ height: 15 }}></div>;
}

const initStates = {
  registry: "",
  name: "",
  gender: "",
  initials: "",
  unit: "",
  department: "",
  active: false,
  found: false,
  email: "",
  id_individual_register: "",
  phone: "",
  bossRegistry: "",
  bossName: "",
};

const ForGotUserSupport = ({ onClose }) => {
  const [typeSelectSend, setTypeSelectSend] = useState({
    email: false,
    whatsapp: false,
  });

  const [states, setStates] = useState({
    email: "",
    phone: "",
  });

  const [isSendMessage, setIsSetMessage] = useState(false);
  const [isMessageSent, setIsMessageSent] = useState(false);
  const [isDisabledButtonSent, setIsDisabledButtonSent] = useState(true);

  async function onSend() {
    let arrHostTarget = {};
    if (typeSelectSend.email) {
      arrHostTarget = { type: "email", account: states.email };
    }
    if (typeSelectSend.whatsapp) {
      arrHostTarget = { type: "whatsapp", account: states.phone };
    }
    arrHostTarget.urlBackEnd = backEndUrl;
    arrHostTarget.urlBackImg = backImgUrl;
    arrHostTarget.urlFrontEnd = frontEndUrl;
    arrHostTarget.urlIncRedirect = `${frontEndUrl}/reset`;

    const result = await api.post("users/forgot_password", arrHostTarget, {});
    if (result) {
      console.log(">>> arrHostTarget", arrHostTarget);
      console.log(">>> Sent result:", result);
    }
    // Seap#n0c@123
  }

  useEffect(() => {
    setIsDisabledButtonSent(true);

    if (typeSelectSend.email && validateEmail(states.email) && states.email.length > 0) {
      setIsDisabledButtonSent(false);
    }

    if (typeSelectSend.whatsapp && states.phone.replace(/\ |\_|\(|\)|\-/g, "").length === 11) {
      setIsDisabledButtonSent(false);
    }
  }, [states]);

  if (isMessageSent) {
    return (
      <Container>
        <Content>
          <Grid container spacing={3} p={3}>
            <Grid item xs={12} lg={12} xl={12}>
              <VuiLabel title="Sua solicitação foi enviada!" sizeTitle={22} />
              <VuiRow />
              <VuiRow />
              <VuiLabel
                title={`Verifique a notificação enviada no seu ${
                  typeSelectSend.email ? `e-mail: "${states.email}"` : `whatsapp: ${states.phone}`
                }`}
                sizeTitle={16}
              />
            </Grid>
            <Grid item xs={12} lg={6} xl={10}></Grid>
            <Grid item xs={12} lg={6} xl={2}>
              <VuiButtonCustom title="Fechar" color="dark" onClick={onClose} />
            </Grid>
          </Grid>
        </Content>
      </Container>
    );
  }

  return (
    <Container>
      <Content>
        <Grid container spacing={3} p={3}>
          {!isSendMessage ? (
            <>
              <Grid item xs={12} lg={12} xl={12}>
                <VuiLabel title="Esqueceu seu acesso ou sua senha!" sizeTitle={22} />
                <VuiRow />
                <VuiRow />
                <VuiLabel
                  title="Para saber quais suas credenciais de acesso ou redefinir sua senha, informe o e-mail ou número de celular cadastrado."
                  sizeTitle={16}
                />
              </Grid>
              {/*  */}
              {/* <Grid item xs={12} lg={12} xl={3} /> */}
              <Grid item xs={12} lg={12} xl={6}>
                <div style={{ display: "flex", justifyContent: "center", marginTop: 0 }}>
                  <VuiRadio
                    name="add"
                    label="Enviar via E-mail"
                    checked={typeSelectSend.email}
                    onClick={() => {
                      setTypeSelectSend((oldState) => ({
                        email: true,
                        whatsapp: false,
                      }));
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} lg={12} xl={6}>
                <div style={{ display: "flex", justifyContent: "center", marginTop: 0 }}>
                  <VuiRadio
                    name="transfer"
                    label="Enviar via Whatsapp"
                    checked={typeSelectSend.whatsapp}
                    onClick={() => {
                      setTypeSelectSend((oldState) => ({
                        whatsapp: true,
                        email: false,
                      }));
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} lg={12} xl={4} />
              {typeSelectSend.whatsapp && (
                <Grid item xs={12} lg={12} xl={4}>
                  <VuiLabel title="Número do Celular Whatsapp" />
                  <VuiInputMask
                    name="phone"
                    placeholder="Informe o número do celular"
                    mask="phone"
                    value={states?.phone}
                    onChange={(e) => {
                      const value = e.target.value;
                      setStates({ ...states, phone: value });
                    }}
                    // error={states?.phone.length === 0 ? "Informe o número do celular" : ""}
                    // onKeyDown={(e) => {
                    //   if (e.key === "Enter" || e.keyCode === 13) FocusNextField("selectUnit");
                    // }}
                  />
                </Grid>
              )}
              {typeSelectSend.email && (
                <Grid item xs={12} lg={12} xl={4}>
                  <VuiLabel title="E-mail" />
                  <VuiInput
                    name="email"
                    placeholder="Informe um e-mail"
                    value={states?.email}
                    onChange={(e) => {
                      const value = e.target.value;
                      setStates({ ...states, email: value });
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.keyCode === 13) FocusNextField("phone");
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={12} lg={12} xl={4} />
              {!typeSelectSend.email && !typeSelectSend.whatsapp && (
                <Grid item xs={12} lg={12} xl={4}>
                  <VuiRow />
                  <VuiRow />
                  <VuiRow />
                  <VuiRow />
                </Grid>
              )}
              {/*  */}
              <Grid item xs={12} lg={6} xl={8}></Grid>
              <Grid item xs={12} lg={6} xl={2}>
                <VuiButtonCustom title="Fechar" color="dark" onClick={onClose} />
              </Grid>
              <Grid item xs={12} lg={6} xl={2}>
                <VuiButtonCustom
                  title="Enviar"
                  color="success"
                  fontWeight="bold"
                  onClick={() => {
                    setIsSetMessage(true);
                  }}
                  disabled={isDisabledButtonSent}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} lg={12} xl={12}>
                <CircularProgressThickness
                  timeOut={100}
                  height={280}
                  label={[
                    { min: 1, max: 100, value: 1, msg: "Aguarde... Enviando sua Solicitação" },
                  ]}
                  onExec={() => {
                    setIsSetMessage(false);
                  }}
                  onProgress={async (n) => {
                    if (n === 45) {
                      console.log(">>> Aqui a mensagem é enviada para o servidor.");
                      onSend();
                    }
                    if (n === 99) {
                      setIsMessageSent(true);
                    }
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Content>
    </Container>
  );
};

const NewUserSupport = ({ lstUnits, lstDepartment, onClose, onSave }) => {
  const [states, setStates] = useState(initStates);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [listDepartment, setListDepartment] = useState({});
  const [departmentSelected, setDepartmentSelected] = useState("");
  const [isDisabledSave, setIsDisabledSave] = useState(true);
  const [isUserNotFound, setIsUserNotFound] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [isFinishProcess, isSetFinishProcess] = useState(false);
  const [isDonneCheck, setIsDonneCheck] = useState({
    check1: false,
    check2: false,
    check3: false,
    check4: false,
    check5: false,
  });
  const [resultSave, setResultSave] = useState({});

  async function createPgp() {
    //
    //
    //
  }

  async function saveUser() {
    const arrSave = {
      ...states,
      password: password,
      department: departmentSelected.split("_")[1],
      login: states.name.split(" ").join(".").toLowerCase(),
      id_group: "2",
    };

    // const token = localStorage.getItem("token");
    // if (token) {
    const res = await api.post(`users/register`, arrSave, {
      // headers: { "Content-Type": "application/json", authorization: token },
    });

    // console.log(">>> saveUser", res.data.data);
    return { ...res.data.data };
    // }
  }

  useEffect(() => {
    const disabledButtonSave =
      states.email.length !== 0 &&
      states.id_individual_register.length !== 0 &&
      states.phone.length !== 0 &&
      states.bossRegistry.length !== 0 &&
      states.bossName.length !== 0 &&
      password.length >= 8 &&
      passwordConfirm.length >= 8 &&
      password === passwordConfirm;
    setIsDisabledSave(!disabledButtonSave);
    // console.log(">>>", !disabledButtonSave, password, passwordConfirm);
  }, [states, password, passwordConfirm]);

  useEffect(() => {
    setIsDonneCheck({
      check1: /[A-Z]/.test(password[0]),
      check2: /[a-z]/.test(password),
      check3: /[0-9]/.test(password),
      check4: /[!@#$%ˆ&*()]/.test(password),
      check5: password.length >= 8,
    });
  }, [password, passwordConfirm]);

  if (isFinishProcess) {
    return (
      <Container>
        <Content>
          <Grid container spacing={3} p={3}>
            <Grid item xs={12} lg={12} xl={12}>
              <VuiLabel title="Parabéns!" sizeTitle={42} />
              <VuiLabel
                title="Suas credenciais foram criadas com sucesso, a partir deste momento você poderá usar seu email ou login para acessar o suporte."
                sizeTitle={28}
              />
              <VuiRow />
              <VuiRow />
              <VuiRow />
              <VuiLabel
                title="Nome: "
                sizeTitle={24}
                subtitle={resultSave.name}
                sizeSubTitle={24}
              />
              <VuiLabel
                title="E-mail: "
                sizeTitle={24}
                subtitle={resultSave.email}
                sizeSubTitle={24}
              />
              <VuiLabel
                title="login: "
                sizeTitle={24}
                subtitle={resultSave.login}
                sizeSubTitle={24}
              />
            </Grid>
            <Grid item xs={12} lg={6} xl={10}>
              <VuiRow />
              <VuiRow />
            </Grid>

            <Grid item xs={12} lg={6} xl={10}></Grid>
            <Grid item xs={12} lg={6} xl={2}>
              <VuiButtonCustom title="Fechar" color="dark" onClick={() => onClose(resultSave)} />
            </Grid>
          </Grid>
        </Content>
      </Container>
    );
  }

  if (loadingSave) {
    return (
      <Container>
        <Content>
          <Grid container spacing={3} p={2}>
            <Grid item xs={12} lg={12} xl={12}>
              <CircularProgressThickness
                label={[
                  { min: 1, max: 79, value: 1, msg: "Aguarde... Salvando informações" },
                  // { min: 41, max: 79, value: 55, msg: "Aguarde... Criando assinatura digital" },
                  { min: 80, max: 100, value: 100, msg: "Aguarde... Finalizando o processo" },
                ]}
                onExec={() => {
                  setLoadingSave(false);
                }}
                onProgress={async (n) => {
                  {
                    if (n === 15) {
                      console.log(">>> Salvar informações aqui", n);
                      const result = await saveUser();
                      setResultSave(result);
                    }
                    // if (n === 55) {
                    //   console.log(">>> Criar assinatura digital", n);
                    //   // const result = await createPgp(resultSave);
                    // }
                    if (n === 85) {
                      console.log(">>> Finalizando processo", n);
                    }
                    if (n >= 99) {
                      isSetFinishProcess(true);
                    }
                  }
                }}
              />
            </Grid>
          </Grid>
        </Content>
      </Container>
    );
  } else {
    return (
      <Container>
        <Content>
          <Grid container spacing={3} p={2}>
            <Grid item xs={12} lg={12} xl={12}>
              <VuiLabel title="Novo usuário" sizeTitle={32} />
              <VuiRow />
              <Grid container spacing={2}>
                {/* Line 1 */}
                <Grid item xs={12} lg={12} xl={3}>
                  <VuiLabel title="Matrícula" />
                  <VuiInput
                    placeholder={`Informe a matricula ou deixe em branco`}
                    value={states.registry}
                    onChange={async (e) => {
                      const value = e.target.value;
                      setStates({ ...states, registry: value });
                      if (value.length > 3) {
                        let arrUserFound = { ...initStates };
                        const res = await api.get(`velti/s?register=${value}`);
                        const dataVelti = res.data;
                        if (dataVelti.found) {
                          // console.log(">>> velti/s?register", dataVelti);
                          const idIndividualRegister = dataVelti.id_individual_register;
                          console.log(">>> idIndividualRegister:", idIndividualRegister);

                          const res = await api.get(
                            `users/s?id_individual_register=${idIndividualRegister}`
                          );
                          const dataUser = res.data?.data || {};
                          // console.log(">>> dataUser:", dataUser);
                          // console.log(">>> dataVelti:", dataVelti);

                          const found = dataUser?.found || false;
                          if (found) {
                            notify(
                              `Usuário "${dataVelti.name.split(" ")[0]} ${
                                dataVelti.name.split(" ")[dataVelti.name.split(" ").length - 1]
                              }" já cadastrad${
                                dataVelti.gender.substr(0, 1) === "M" ? "o" : "a"
                              }...`
                            );
                          } else {
                            arrUserFound = { ...dataVelti };
                            const lstFilterDepartment = lstDepartment.filter(
                              (x) => x.value.split("_")[0] === dataVelti.initials
                            );
                            setListDepartment(lstFilterDepartment);
                          }
                        }
                        setStates({ ...states, ...arrUserFound, registry: value });
                      } else if (value.length === 0 || value === 0) {
                        setStates(initStates);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={12} xl={3}>
                  <VuiLabel title="CPF" />
                  <VuiInputMask
                    placeholder="Informe seu CPF"
                    mask="cpf"
                    value={states?.id_individual_register}
                    onChange={async (e) => {
                      const value = e.target.value;
                      const lengthValue = value.replace(/\_|\.|\-/g, "").length;
                      setStates({ ...states, id_individual_register: value });
                      if (lengthValue === 11) {
                        if (validateCPF(value.replace(/\_|\.|\-/g, ""))) {
                          const res = await api.get(`users/s?id_individual_register=${value}`);
                          const dataUser = res.data?.data || {};
                          const found = dataUser?.found || false;
                          if (found) {
                            notify(
                              `Usuário "${dataUser.name.split(" ")[0]} ${
                                dataUser.name.split(" ")[dataUser.name.split(" ").length - 1]
                              }" já cadastrad${dataUser.gender.substr(0, 1) === "M" ? "o" : "a"}...`
                            );
                          } else {
                            FocusNextField("userName");
                          }
                        } else {
                          notify(`Número de CPF "${value}" inválido`);
                        }
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={12} xl={6}>
                  <VuiLabel title="Nome" />
                  <VuiInput
                    name="userName"
                    disabled={isUserNotFound}
                    placeholder={`Informe o nome completo`}
                    value={states.name}
                    onChange={(e) => {
                      if (!states.found) {
                        const value = e.target.value;
                        setStates({ ...states, name: value });
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.keyCode === 13) FocusNextField("email");
                    }}
                  />
                </Grid>

                {/*  */}
                {states.name.length > 0 ? (
                  <>
                    {/* Line 2 */}
                    <Grid item xs={12} lg={12} xl={4}>
                      <VuiLabel title="E-mail" />
                      <VuiInput
                        name="email"
                        placeholder="Informe um e-mail"
                        value={states?.email}
                        onChange={(e) => {
                          const value = e.target.value;
                          setStates({ ...states, email: value });
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" || e.keyCode === 13) FocusNextField("phone");
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} lg={12} xl={4}>
                      <VuiLabel title="Número do Celular Whatsapp" />
                      <VuiInputMask
                        name="phone"
                        placeholder="Informe o número do celular"
                        mask="phone"
                        value={states?.phone}
                        onChange={(e) => {
                          const value = e.target.value;
                          setStates({ ...states, phone: value });
                        }}
                        // onKeyDown={(e) => {
                        //   if (e.key === "Enter" || e.keyCode === 13) FocusNextField("selectUnit");
                        // }}
                      />
                    </Grid>

                    {/* Line 3 */}
                    {states.found && states.unit.length > 0 && (
                      <Grid item xs={12} lg={12} xl={6}>
                        <VuiLabel title="SEDE/Unidade" />
                        <VuiInput value={states?.unit} disabled />
                      </Grid>
                    )}

                    {states.found && states.department.length > 0 && (
                      <Grid item xs={12} lg={12} xl={6}>
                        <VuiLabel title="Departamento" />
                        <VuiInput value={states?.department} disabled />
                      </Grid>
                    )}

                    {!states.found && states.name.length > 0 && (
                      <Grid item xs={12} lg={12} xl={6}>
                        <VuiSelect
                          disabled
                          label="SEDE/Unidade"
                          name="selectUnit"
                          placeholder="Informe a Unidade que está lotado"
                          options={lstUnits}
                          onCustomChange={async (e) => {
                            const lstFilterDepartment = lstDepartment.filter(
                              (x) => x.value.split("_")[0] === e.value
                            );
                            setListDepartment(lstFilterDepartment);
                            setStates({ ...states, initials: e.value, unit: e.label });
                          }}
                        />
                      </Grid>
                    )}

                    {states.name.length > 0 && states.department.length === 0 && (
                      <Grid item xs={12} lg={12} xl={6}>
                        <VuiSelect
                          disabled
                          label="Departamento"
                          name="selectDepartment"
                          placeholder="Informe o departamento que está lotado"
                          options={listDepartment}
                          onCustomChange={async (e) => {
                            console.log(">>> listDepartment:", e);
                            setDepartmentSelected(e.value);
                            // setLstUnits(e.value);
                          }}
                          // defaultInputValue={userLogged?.unit}
                        />
                      </Grid>
                    )}

                    {/* Line 4 */}
                    <Grid item xs={12} lg={12} xl={4}>
                      <VuiLabel title="Chefe do Departamento (matricula)" />
                      <VuiInput
                        placeholder={`Informe a matricula campo obrigatório`}
                        value={states.bossRegistry}
                        onChange={async (e) => {
                          const value = e.target.value;
                          setStates({ ...states, bossRegistry: value });
                          if (value.length > 3) {
                            let arrUserFound = { bossName: "" };
                            const res = await api.get(`velti/s?register=${value}`);
                            if (res.data.found) {
                              arrUserFound = { bossName: res.data.name };
                              FocusNextField("password");
                            }
                            setStates({ ...states, ...arrUserFound, bossRegistry: value });
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" || e.keyCode === 13) FocusNextField("password");
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} lg={12} xl={8}>
                      <VuiLabel title="Nome" />
                      <VuiInput
                        placeholder={`Informe o nome completo`}
                        value={states.bossName}
                        disabled
                      />
                    </Grid>

                    {/* Line 5 */}
                    <Grid item xs={12} lg={12} xl={6}>
                      <VuiLabel title="Senha" />
                      <VuiInput
                        name="password"
                        type="password"
                        placeholder="Informe uma senha forte com Maiusculas, caracter especiais e padrão"
                        value={password}
                        onChange={(e) => {
                          const value = e.target.value;
                          setPassword(value);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" || e.keyCode === 13) FocusNextField("password");
                        }}
                      />
                      <div style={{ fontSize: 11, color: "#fff", marginTop: 5, marginBottom: 5 }}>
                        Sua senha, deverá conter no mínimo oito caracteres e deverá obedecer os
                        requisitos:
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          {isDonneCheck.check1 ? (
                            <IoCheckmarkDoneSharp color={colors.online.main} size={18} />
                          ) : (
                            <IoCheckmarkSharp size={18} />
                          )}
                          <p style={{ marginLeft: 5 }}>Primeira letras maiúsculas (A-Z)</p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          {isDonneCheck.check2 ? (
                            <IoCheckmarkDoneSharp color={colors.online.main} size={18} />
                          ) : (
                            <IoCheckmarkSharp size={18} />
                          )}
                          <p style={{ marginLeft: 5 }}>Letras minúsculas (a-z)</p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          {isDonneCheck.check3 ? (
                            <IoCheckmarkDoneSharp color={colors.online.main} size={18} />
                          ) : (
                            <IoCheckmarkSharp size={18} />
                          )}
                          <p style={{ marginLeft: 5 }}>Números (0-9)</p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          {isDonneCheck.check4 ? (
                            <IoCheckmarkDoneSharp color={colors.online.main} size={18} />
                          ) : (
                            <IoCheckmarkSharp size={18} />
                          )}
                          <p style={{ marginLeft: 5 }}>Caracteres especiais ($, %, @, ...)</p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          {isDonneCheck.check4 ? (
                            <IoCheckmarkDoneSharp color={colors.online.main} size={18} />
                          ) : (
                            <IoCheckmarkSharp size={18} />
                          )}
                          <p style={{ marginLeft: 5 }}>
                            Tamanho da senha igual ou maior que 8 dígitos
                          </p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={12} xl={6}>
                      <VuiLabel title="Confirme a Senha" />
                      <VuiInput
                        name="passwordConfirm"
                        type="password"
                        placeholder="Confirme a senha informada"
                        value={passwordConfirm}
                        onChange={(e) => {
                          const value = e.target.value;
                          setPasswordConfirm(value);
                        }}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12} lg={12} xl={12}>
                      <VuiRow />
                      <VuiRow />
                      <VuiRow />
                    </Grid>
                    <Grid item xs={12} lg={12} xl={12}>
                      <VuiRow />
                      <VuiRow />
                      <VuiRow />
                    </Grid>
                    <Grid item xs={12} lg={12} xl={12}>
                      <VuiRow />
                      <VuiRow />
                      <VuiRow />
                    </Grid>
                    <Grid item xs={12} lg={12} xl={12}>
                      <VuiRow />
                      <VuiRow />
                      <VuiRow />
                    </Grid>
                    <Grid item xs={12} lg={12} xl={12}>
                      <VuiRow />
                      <VuiRow />
                      <VuiRow />
                      <VuiRow />
                      <VuiRow />
                    </Grid>
                    <Grid item xs={12} lg={12} xl={12}>
                      <VuiRow />
                      <VuiRow />
                      <VuiRow />
                      <VuiRow />
                      <VuiRow />
                    </Grid>
                  </>
                )}

                {/*  */}
                <Grid item xs={12} lg={6} xl={8}></Grid>
                <Grid item xs={12} lg={6} xl={2}>
                  <VuiButtonCustom title="Fechar" color="dark" onClick={() => onClose(null)} />
                </Grid>
                <Grid item xs={12} lg={6} xl={2}>
                  <VuiButtonCustom
                    title="Salvar"
                    color="success"
                    fontWeight="bold"
                    onClick={() => {
                      setLoadingSave(true);
                      setIsDisabledSave(true);
                    }}
                    disabled={isDisabledSave}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Container>
    );
  }
};

function SignIn() {
  const [controller, dispatch] = useVisionUIController();
  const [toDashboard, setToDashboard] = useState(false);
  const [loading, setLoading] = useState(true);
  const [lstUnits, setLstUnits] = useState({});
  const [lstDepartment, setLstDepartment] = useState({});
  const [rememberMe, setRememberMe] = useState(true);
  const [userName, setUserName] = useState("");
  const [passWord, setPassWord] = useState("");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModalForGot, setIsOpenModalForGot] = useState(false);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const history = useHistory();
  const pathRouter = history.location.search
    ? "/" + history.location.search.split("/")[1]
    : "/admin";
  const isPathSupport = pathRouter === PAGE_SUPPORT;

  const login = async () => {
    const res = await api.post("users/login", { email: userName, passwd: passWord });
    const data = res.data;

    if (data.success) {
      // if (userInfo.id_group === GROUP_ASCOM)
      //   userInfo.timeStampOut = parseInt(moment("06/15/2023 23:59:59").format("X") * 1000);

      const userInfo = data.data;
      userInfo.dateTimeOut = moment(userInfo.timeStampOut).format("DD/MM/YYYY HH:mm:ss");

      localStorage.clear();
      localStorage.setItem("timeStampOut", userInfo.timeStampOut);
      localStorage.setItem("token", userInfo.token);
      localStorage.setItem("logged", data.success);
      localStorage.setItem("user", JSON.stringify(userInfo));

      setUserLogged(dispatch, userInfo);

      history.push(pathRouter);
      setToDashboard(true);
    } else {
      notify(data.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    async function loadUnits() {
      try {
        const res = await api.get(`units/selects/`);
        setLstUnits(
          res.data.data.map((x) => {
            return { label: `${x.initials} - ${x.name}`, value: x.initials };
          })
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(">>> Error:loadUnits:", error);
      }
    }
    async function loadDepartment() {
      try {
        const res = await api.get(`departments/s?initials=all&fields=initials,department,acronym`);
        setLstDepartment(
          res.data.data.map((x) => {
            return { label: `${x.acronym} - ${x.department}`, value: x.initials + "_" + x.acronym };
          })
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(">>> Error:loadDepartment:", error);
      }
    }
    loadUnits();
    loadDepartment();
  }, [false]);

  return (
    <CoverLayout
      title="Área Restrita"
      color="white"
      description={"Digite suas credenciais para entrar"}
      premotto="INSPIRADO PELO FUTURO:"
      motto=""
      image={bgSignIn}
    >
      <Toaster position="top-right" reverseOrder={false} />

      <VuiModal
        isOpen={isOpenModal}
        onClose={() => {
          setIsOpenModal(!isOpenModal);
        }}
      >
        <NewUserSupport
          lstUnits={lstUnits}
          lstDepartment={lstDepartment}
          onClose={(e) => {
            console.log(">>> onClose", e);
            if (e) {
              console.log(">>> onClose(e)", e);
              setUserName(e.login);
              setPassWord("");
              FocusNextField("password");
            }
            setIsOpenModal(!isOpenModal);
          }}
          onSave={(e) => {
            console.log(">>> NewUserSupport", JSON.stringify(e));
            setUserName(e.login);
            setPassWord("");
            FocusNextField("password");
          }}
        />
      </VuiModal>

      <VuiModal
        isOpen={isOpenModalForGot}
        onClose={() => {
          setIsOpenModalForGot(!isOpenModalForGot);
        }}
      >
        <ForGotUserSupport onClose={() => setIsOpenModalForGot(!isOpenModalForGot)} />
      </VuiModal>

      {loading ? (
        <VuiLoading />
      ) : (
        <VuiBox component="form" role="form">
          <VuiBox mb={2}>
            <VuiBox mb={0} ml={0.5}>
              <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
                Usuário/E-mail
              </VuiTypography>
            </VuiBox>
            <GradientBorder
              minWidth="100%"
              padding="1px"
              borderRadius={"1px"}
              backgroundImage={radialGradient(
                colors.gradients.seap.main,
                colors.gradients.seap.state,
                colors.gradients.seap.angle
              )}
            >
              <VuiInput
                name="userName"
                value={userName}
                onChange={(e) => {
                  setUserName(e.target.value);
                }}
                type="email"
                placeholder="Digite seu Usuário/E-mail"
                fontWeight="bold"
                sx={({ typography: { size } }) => ({
                  fontSize: size.sm,
                })}
              />
            </GradientBorder>
          </VuiBox>
          <VuiBox mb={2}>
            <VuiBox mb={0} ml={0.5}>
              <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
                Senha
              </VuiTypography>
            </VuiBox>
            <GradientBorder
              minWidth="100%"
              borderRadius={borders.borderRadius.lg}
              padding="1px"
              backgroundImage={radialGradient(
                colors.gradients.seap.main,
                colors.gradients.seap.state,
                colors.gradients.seap.angle
              )}
            >
              <VuiInput
                name="password"
                value={passWord}
                onChange={(e) => {
                  setPassWord(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.keyCode == 13) {
                    login();
                  }
                }}
                type="password"
                autocomplete="current-password"
                placeholder="Digite sua senha..."
                sx={({ typography: { size } }) => ({
                  fontSize: size.sm,
                })}
              />
            </GradientBorder>
          </VuiBox>
          <VuiBox display="flex" alignItems="center">
            <VuiSwitch color="success" checked={rememberMe} onChange={handleSetRememberMe} />
            <VuiTypography
              variant="caption"
              color="white"
              fontWeight="medium"
              onClick={handleSetRememberMe}
              sx={{ cursor: "pointer", userSelect: "none" }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;Lembre-se de mim
            </VuiTypography>
          </VuiBox>

          <VuiBox mt={2} mb={0}>
            <VuiButton
              color="success"
              fullWidth
              onClick={() => {
                login();
              }}
            >
              Entrar
            </VuiButton>
          </VuiBox>

          <VuiBox mt={2} mb={0}>
            <VuiButtonCustom
              title="Criar um novo usuário"
              color="transparent"
              icon="person_add"
              // iconSize="small"
              iconSide="left"
              onClick={() => setIsOpenModal(!isOpenModal)}
            />
          </VuiBox>

          <VuiBox mt={0} mb={0}>
            <VuiButtonCustom
              title="Esqueci meu acesso ou minha senha"
              color="transparent"
              // icon="shuffle"
              // iconSize="medium"
              iconSide="left"
              onClick={() => setIsOpenModalForGot(!isOpenModal)}
            />
          </VuiBox>
          {/*  */}
        </VuiBox>
      )}
    </CoverLayout>
  );
}

export default SignIn;
