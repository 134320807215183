import React from "react";
import { Card, Icon, Stack } from "@mui/material";

import Printers from "./components/DashPrinter";

function PrinterStatus() {
  return (
    <Card sx={{ backgroundColor: "green" }}>
      <Printers />
    </Card>
  );
}

export default PrinterStatus;
