import React from "react";
import { Card, Icon } from "@mui/material";

import ClockTimes from "./components/DashClockTimes";

function LinkStatus() {
  return (
    <Card sx={{ backgroundColor: "green" }}>
      <ClockTimes />
    </Card>
  );
}

export default LinkStatus;
