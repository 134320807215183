import React, { useState } from "react";

import "./styles.css";

const Marker = ({
  color,
  name,
  enabled = true,
  status = true,
  tooltip,
  colorText = "black",
  onClick = () => {},
}) => {
  return (
    <div onClick={onClick}>
      <div
        className="pin bounce"
        style={{ backgroundColor: enabled ? (status ? color : "red") : "red", cursor: "pointer" }}
        title={tooltip}
      />
      {status ? null : <div className="pulse" />}
      <div
        style={{
          marginTop: 15,
          marginLeft: -23,
          fontWeight: "bold",
          color: enabled ? colorText : "red",
        }}
      >
        {name}
      </div>
    </div>
  );
};

export default Marker;
