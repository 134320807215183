import { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";

// Authentication layout components
import CoverLayout from "./components/CoverLayout";

import api from "services/api";

import Login from "./sign-in";

const Loading = () => {
  return <CoverLayout></CoverLayout>;
};

async function checkTokenIsValid(token, cb) {
  const res = await api.get("jwt/checkToken", {
    headers: {
      authorization: token,
    },
  });
  const tokenItens = res.data.data ? res.data.data : { validToken: false };
  cb(tokenItens);
}

function SignIn() {
  const [isLogged, setIsLogged] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const dateNow = new Date();
    const nowTimeStamp = dateNow.getTime();
    const isLoggedStorage = localStorage.getItem("logged");
    const timeStampStorage = parseInt(localStorage.getItem("timeStampOut"));

    if (isLoggedStorage && nowTimeStamp <= timeStampStorage) {
      setIsLogged(isLoggedStorage);
      setLoading(true);
    } else {
      const token = localStorage.getItem("token");
      if (token) {
        checkTokenIsValid(token, (checkToken) => {
          setIsLogged(checkToken.validToken);
        });
      }
      setLoading(true);
    }
  }, [isLogged, loading]);

  if (!loading) {
    return <Loading />;
  }

  return isLogged ? <Redirect to="/" /> : <Login />;
}

export default SignIn;
