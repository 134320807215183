// Vision UI Dashboard React example components
import PageLayout from "examples/LayoutContainers/PageLayout";
import ResetPassword from "./components/ResetPassword";

function ResetSupport() {
  return (
    <PageLayout>
      <ResetPassword />
    </PageLayout>
  );
}

export default ResetSupport;
