import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";

// Vision UI Dashboard React components
import VuiTypography from "components/VuiTypography";
import VuiBox from "components/VuiBox";
import VuiProgress from "components/VuiProgress";
import VuiInfoItemUnit from "components/VuiInfoItemUnit";
import VuiLoading from "components/VuiLoading";
import VuiLabel from "components/VuiLabel";

//
import breakpoints from "assets/theme/base/breakpoints";
import { navbarIconButton } from "examples/Navbars/DashboardNavbar/styles";

import api from "services/api";
import colors from "assets/theme/base/colors";
import moment from "moment";

import useWindowDimensions from "hooks/useWindowDimensions";

const padLeft = (num, places) => String(num).padStart(places, "0");

const ButtonIconItem = ({ name = "person", fontSizeIcon = "32px", onClick }) => {
  return (
    <IconButton
      size="small"
      color="inherit"
      sx={navbarIconButton}
      variant="contained"
      onClick={onClick}
    >
      <Icon fontSize={fontSizeIcon}>{name}</Icon>
    </IconButton>
  );
};

const INFO_TABLE_GRID = 0;
const INFO_TABLE_LIST = 1;

function Units() {
  const [refreshInterval, setRefreshInterval] = useState(60000);
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState(null);
  const [toogleInfo, setToogleInfo] = useState(INFO_TABLE_GRID);
  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [isGroupAdmin, setIsGroupAdmin] = useState(0);

  // const { search } = useLocation();
  // const actionCommand = search.replace("?", "").split("=")[0] || "";
  // const actionExec = search.replace("?", "").split("=")[1] || "";
  // console.log(">>> Search:actionCommand:", actionCommand, "- Search:actionExec:", actionExec);

  async function loadLinks() {
    const res = await api.get("Units");
    // console.log(">>> loadLinks:Units", res.data);
    const data = res.data;
    setRows(data);
    setLoading(false);
  }

  useEffect(() => {
    loadLinks();
  }, [false]);

  useEffect(() => {
    setIsGroupAdmin(JSON.parse(localStorage.getItem("user")).id_group === 1);
    if (refreshInterval && refreshInterval > 0) {
      const interval = setInterval(loadLinks, refreshInterval);
      return () => clearInterval(interval);
    }
  }, [refreshInterval]);

  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
        setMobileNavbar(false);
      } else {
        setMobileView(false);
        setMobileNavbar(false);
      }
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener("resize", displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  return (
    <Card
      sx={{
        height: "100% !important",
      }}
    >
      <VuiBox
        sx={{
          "& th": {
            borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
              `${borderWidth[1]} solid ${grey[700]}`,
          },
          "& .MuiTableRow-root:not(:last-child)": {
            "& td": {
              borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                `${borderWidth[1]} solid ${grey[700]}`,
            },
          },
        }}
      >
        {loading ? (
          <VuiLoading />
        ) : (
          <VuiBox>
            <div
              style={{
                display: "grid",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                // fontSize: 24,
                // marginBottom: 20,
              }}
            >
              <VuiLabel
                title={`Total de ${rows.length} /Unidades /Presidios /Administrativo`}
                sizeTitle={"24px"}
                colorTitle="warning"
              />
              <VuiProgress value={100} color="error" label={false} />

              {mobileView ? null : (
                <VuiTypography
                  color="white"
                  variant="lg"
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  pt={"10px"}
                >
                  <ButtonIconItem
                    name="grid_view"
                    onClick={() => {
                      setToogleInfo(INFO_TABLE_GRID);
                    }}
                  />

                  <div style={{ width: 5 }} />

                  <ButtonIconItem
                    name="list_view"
                    onClick={() => {
                      setToogleInfo(INFO_TABLE_LIST);
                    }}
                  />
                </VuiTypography>
              )}
            </div>

            {toogleInfo === INFO_TABLE_GRID ? (
              <Box sx={{ flexGrow: 2 }}>
                <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 3, sm: 6, md: 18 }}>
                  {rows.map((item, index) => {
                    return (
                      <Grid item xs={6} sm={6} md={6} key={index}>
                        <VuiInfoItemUnit item={item} isGroupAdmin={isGroupAdmin} />
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            ) : null}
          </VuiBox>
        )}
      </VuiBox>
    </Card>
  );
}

export default Units;
