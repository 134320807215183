import { useState, useEffect } from "react";

// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiLabel from "components/VuiLabel";

// Vision UI Dashboard React example components
import DefaultNavbarLink from "examples/Navbars/DefaultNavbar/DefaultNavbarLink";
import DefaultNavbarMobile from "examples/Navbars/DefaultNavbar/DefaultNavbarMobile";

// Vision UI Dashboard React base styles
import breakpoints from "assets/theme/base/breakpoints";
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Custom styles for DashboardNavbar
import { navbarIconButton } from "examples/Navbars/DashboardNavbar/styles";

// Vision UI Dashboard React contexts
import { useVisionUIController } from "context";

function DefaultNavbar({ transparent, light, gotoPage = "/", action }) {
  const [controller] = useVisionUIController();
  const isLoggedStorage = localStorage.getItem("logged");
  const userLogged = controller.user;
  const { borderCol } = colors;
  const { borderWidth } = borders;
  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  const openMobileNavbar = ({ currentTarget }) => setMobileNavbar(currentTarget.parentNode);
  const closeMobileNavbar = () => setMobileNavbar(false);

  const [anchorElMenuUser, setAnchorElMenuUser] = useState(null);
  const openMenuUser = Boolean(anchorElMenuUser);

  const closeMenuUser = () => {
    setAnchorElMenuUser(null);
  };

  const clickMenuUser = (event) => {
    setAnchorElMenuUser(event.currentTarget);
  };

  const renderMenu = () => (
    <Menu id="basic-menu" anchorEl={anchorElMenuUser} open={openMenuUser} onClose={closeMenuUser}>
      <VuiBox color="text" width="350px" sx={{ padding: "10px" }}>
        <VuiLabel sizeTitle={14} title="Usuário:" subtitle={userLogged.name} />
        <VuiLabel sizeTitle={14} title="Grupo:" subtitle={userLogged.nameGroup} />
        <VuiLabel sizeTitle={14} title="A sessão expira em:" subtitle={userLogged.dateTimeOut} />
      </VuiBox>
    </Menu>
  );

  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
        setMobileNavbar(false);
      } else {
        setMobileView(false);
        setMobileNavbar(false);
      }
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener("resize", displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  return (
    <Container>
      <VuiBox
        py={2}
        px={{ xs: transparent ? 4 : 3, sm: transparent ? 2 : 3, lg: transparent ? 0 : 3 }}
        my={2}
        border={`${borderWidth[1]} solid ${borderCol.navbar}`}
        // width="calc(100% - 8px)"
        width="100%"
        borderRadius="xl"
        shadow={transparent ? "none" : "md"}
        color={light ? "white" : "dark"}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        position="absolute"
        left="50%"
        zIndex={3}
        maxWidth="1600px"
        sx={({ palette: { gradients }, functions: { linearGradient } }) => ({
          backgroundColor: linearGradient(
            gradients.navbar.main,
            gradients.navbar.state,
            gradients.navbar.deg
          ),
          backdropFilter: "blur(42px)",
          transform: "translate(-50%, 0px)",
        })}
      >
        <VuiBox component={Link} to={gotoPage} py={transparent ? 1.5 : 0.75} lineHeight={1}>
          <VuiTypography
            variant="button"
            textGradient={true}
            color="logo"
            fontSize={14}
            letterSpacing={2}
            fontWeight="bold"
            sx={{
              margin: "0 auto",
            }}
          >
            Monitor Dashboard Admin
          </VuiTypography>
        </VuiBox>
        {isLoggedStorage && (
          <VuiBox color="inherit" display={{ xs: "none", lg: "flex" }} m={0} p={0}>
            <DefaultNavbarLink icon="donut_large" name="dashboard" route="/dashboard" />
            {controller.user.name && (
              <IconButton
                size="small"
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={clickMenuUser}
              >
                <Icon fontSize="32px">person</Icon>
                <VuiTypography
                  variant="button"
                  fontWeight="regular"
                  color="white"
                  textTransform="capitalize"
                  sx={{ width: "100%", lineHeight: 0 }}
                >
                  &nbsp;Usuário
                </VuiTypography>
              </IconButton>
            )}
            {renderMenu()}
            {action && (
              <DefaultNavbarLink icon="logout" name="logout" route="/" onClick={action.exec} />
            )}
          </VuiBox>
        )}

        <VuiBox
          display={{ xs: "inline-block", lg: "none" }}
          lineHeight={0}
          py={1}
          pl={1}
          color="inherit"
          sx={{ cursor: "pointer" }}
          onClick={openMobileNavbar}
        >
          <Icon
            sx={({ palette: { white } }) => ({
              color: white.main,
            })}
            fontSize="default"
          >
            {mobileNavbar ? "close" : "menu"}
          </Icon>
        </VuiBox>
      </VuiBox>
      {mobileView && <DefaultNavbarMobile open={mobileNavbar} close={closeMobileNavbar} />}
    </Container>
  );
}

// Setting default values for the props of DefaultNavbar
DefaultNavbar.defaultProps = {
  transparent: false,
  light: false,
  action: false,
};

// Typechecking props for the DefaultNavbar
DefaultNavbar.propTypes = {
  transparent: PropTypes.bool,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]).isRequired,
      route: PropTypes.string.isRequired,
      color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
      ]),
      label: PropTypes.string.isRequired,
    }),
  ]),
};

export default DefaultNavbar;
