import { createContext, useContext, useReducer } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import moment from "moment";

// The Vision UI Dashboard  Material main context
const VisionUI = createContext();

// Setting custom name for the context which is visible on react dev tools
VisionUI.displayName = "VisionUIContext";

// Vision UI Dashboard React reducer
function reducer(state, action) {
  // console.log(">>> reducer", action.type, action.value);
  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: action.value };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value };
    }
    case "DIRECTION": {
      return { ...state, direction: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "LOGGED": {
      return { ...state, isLogged: action.value };
    }
    case "USER": {
      return { ...state, user: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Vision UI Dashboard React context provider
function VisionUIControllerProvider({ children }) {
  const initialState = {
    miniSidenav: true,
    transparentSidenav: false,
    sidenavColor: "success",
    transparentNavbar: false,
    fixedNavbar: true,
    openConfigurator: false,
    direction: "ltr",
    layout: "dashboard",
    remote_addr: "localhost",
    isLogged: false,
    user: {},
  };

  // const dateNow = new Date();
  // const nowTimeStamp = dateNow.getTime();
  // const isLoggedStorage = localStorage.getItem("logged");
  // const timeStampStorage = parseInt(localStorage.getItem("timeStampOut"));

  // console.log(">>> VisionUIControllerProvider Info localStorage <<");
  // console.log("");
  // console.log(">>> isLoggedStorage .....:", isLoggedStorage);
  // console.log(">>> nowTimeStamp ........:", moment(nowTimeStamp).format("DD/MM/YYYY HH:mm:ss"));
  // console.log(">>> timeStampStorage ....:", moment(timeStampStorage).format("DD/MM/YYYY HH:mm:ss"));

  // console.log(
  //   ">>> isLoggedStorage .....:",
  //   isLoggedStorage,
  //   nowTimeStamp,
  //   timeStampStorage,
  //   nowTimeStamp <= timeStampStorage
  // );

  // if (isLoggedStorage || nowTimeStamp <= timeStampStorage) {
  //   console.log(">>> relogged ............:", isLoggedStorage);

  //   localStorage.clear();
  // }
  // console.log("");

  fetch(document.location).then((res) => {
    const remote_addr = res.headers.get("remote_addr");
    if (remote_addr) {
      console.log(">>> context", remote_addr);
      initialState.remote_addr = remote_addr;
    }
  });

  const [controller, dispatch] = useReducer(reducer, initialState);

  return <VisionUI.Provider value={[controller, dispatch]}>{children}</VisionUI.Provider>;
}

// Vision UI Dashboard React custom hook for using context
function useVisionUIController() {
  const context = useContext(VisionUI);
  // console.log(">>> useVisionUIController", JSON.parse(localStorage.getItem("user")));

  if (!context) {
    throw new Error("useVisionUIController should be used inside the VisionUIControllerProvider.");
  }

  return context;
}

// Typechecking props for the VisionUIControllerProvider
VisionUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatch, value) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDirection = (dispatch, value) => dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setIsLoggedContext = (dispatch, value) => dispatch({ type: "LOGGED", value });
const setUserLogged = (dispatch, value) => dispatch({ type: "USER", value });

export {
  VisionUIControllerProvider,
  useVisionUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setIsLoggedContext,
  setUserLogged,
};
