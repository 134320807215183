// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";

// Vision UI Dashboard React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import PageLayout from "examples/LayoutContainers/PageLayout";

function BasicLayout({ children, gotoPage = "/", action = null }) {
  return (
    <PageLayout>
      <DefaultNavbar gotoPage={gotoPage} action={action} />
      <VuiBox mx={1} pt={12}>
        {children}
      </VuiBox>
    </PageLayout>
  );
}

export default BasicLayout;
