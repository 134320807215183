import colors from "assets/theme/base/colors";
import pxToRem from "assets/theme/functions/pxToRem";
import { Icon } from "@mui/material";

const VuiButtonCustom = ({
  title,
  color,
  fontSize = 12,
  fontWeight = "normal",
  disabled = false,
  icon = "",
  iconSize = "medium",
  iconSide = "right",
  onClick,
}) => {
  return (
    // <Container color={color} onClick={onClick}>
    //   {title}
    // </Container>
    <div
      onClick={!disabled ? onClick : null}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: !disabled ? colors[color].main : colors.socialMediaColors["slack"].dark,
        color: !disabled ? colors.white.main : colors.grey[400],
        fontSize: fontSize,
        fontWeight: fontWeight,
        borderRadius: color !== "transparent" ? pxToRem(4) : 0,
        height: color !== "transparent" ? pxToRem(38) : pxToRem(38),
        cursor: !disabled ? "pointer" : "not-allowed",
      }}
    >
      {icon && iconSide === "left" && (
        <Icon fontSize={iconSize} sx={{ fontWeight: { fontWeight }, mr: "10px", ml: "5px" }}>
          {icon}
        </Icon>
      )}

      {title}

      {icon && iconSide === "right" && (
        <Icon fontSize={iconSize} sx={{ fontWeight: { fontWeight }, mr: "10px", ml: "5px" }}>
          {icon}
        </Icon>
      )}
    </div>
  );
};

export default VuiButtonCustom;
