import { useState, useEffect } from "react";
import { useLocation, Redirect } from "react-router-dom";

import copy from "copy-to-clipboard";

// @mui material components
import { experimentalStyled as styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Card } from "@mui/material";
import { IoCopyOutline } from "react-icons/io5";

import VuiBox from "components/VuiBox";
import VuiLabel from "components/VuiLabel";
import VuiInput from "components/VuiInput";
import VuiInputText from "components/VuiInputText";
import VuiDatePicker from "components/VuiDatePickers";
import VuiButton from "components/VuiButton";
import VuiLoading from "components/VuiLoading";

// Vision UI Dashboard React example components
import Footer from "examples/Footer";
import BasicLayout from "./components/BasicLayout";

import checkExpiredToken from "../../helpers/checkExpiredToken";

import colors from "assets/theme/base/colors";

import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import moment from "moment";

// Service Axios
import api from "services/api";

const statusOccurrence = [
  "Aberto - Aguardando informações",
  "Entregue via e-mail. Aguardando informações",
  "Entregue via whatsapp",
  "Entregue via telegram",
  "Aguardando Manutenção",
  "Encerrado",
];

const CardInfoLink = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.black.modal,
  color: theme.palette.text.secondary,
  borderRadius: 10,
}));

function SLIOccurrence(d1, d2) {
  // console.log(">>> SLIOccurrence", d1, d2);
  const ms = moment(d1, "DD/MM/YYYY HH:mm:ss").diff(moment(d2, "DD/MM/YYYY HH:mm:ss"));
  const d = moment.duration(ms);
  return Math.floor(d.asHours()) + "h" + moment.utc(ms).format(" mm") + "m";
}

function OccurrenceLink() {
  const [loading, setLoading] = useState(true);
  const [isSaveOccurrence, setIsSaveOccurrence] = useState(false);
  const [isDisabledButton, setIsDisabledButton] = useState(true);
  const [isLoadOccurrence, setIsLoadOccurrence] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [states, setStates] = useState({});

  const { search } = useLocation();
  const actionCommand = search.replace("?", "").split("=")[0];
  const actionExec = search.replace("?", "").split("=")[1];

  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    const copyText = `${states.carrier}${
      states.subCarrier ? "(" + states.subCarrier + ") - " : " - "
    }(RB/GW: ${states.gateway}) - ${states.circuit}`;

    copy(copyText);

    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  async function loadOccurrence() {
    setLoading(true);
    const res = await api.get(`occurrence/s?id=${actionExec}`);
    setStates(res.data);
    setTimeout(() => {
      setLoading(false);
      setIsLoadOccurrence(true);
    }, 1000);
  }

  async function saveOccurrence() {
    setLoading(true);
    const token = localStorage.getItem("token");
    if (token) {
      const res = await api.post(
        `occurrence/update`,
        {
          ...states,
          occurrenceStatus: "0",
          status: "2",
          occurrenceDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
        },
        { headers: { "Content-Type": "application/json", authorization: token } }
      );

      console.log(">>>", res.data);

      setTimeout(() => {
        setLoading(false);
        setIsSaveOccurrence(true);
      }, 500);
    } else {
      setLoading(false);
      setIsSaveOccurrence(false);
      CheckLogin();
    }
  }

  function closeOccurrence() {
    window.opener = null;
    window.open("", "_self");
    window.close();
  }

  useEffect(() => {
    if (actionCommand && actionExec) {
      console.log(">>> actionCommand", actionCommand, " actionExec", actionExec);
      loadOccurrence();
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [false]);

  useEffect(() => {
    setIsDisabledButton(
      !(
        (states.occurrenceOperatorName ? states.occurrenceOperatorName.length : 0) > 0 &&
        (states.occurrenceProtocolNumber ? states.occurrenceProtocolNumber.length : 0) > 0 &&
        (states.occurrenceCalledTechnician ? states.occurrenceCalledTechnician.length : 0) > 0 &&
        (states.occurrenceComments ? states.occurrenceComments.length : 0) > 0
      )
    );
  }, [states]);

  if (loading) {
    return (
      <BasicLayout>
        <CardInfoLink>
          <VuiLoading />
        </CardInfoLink>
        <Footer />
      </BasicLayout>
    );
  }

  if (!isLoadOccurrence) {
    return (
      <BasicLayout>
        <CardInfoLink>
          <VuiBox
            style={{
              display: "flex",
              padding: 20,
              height: 400,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <VuiLabel title="Nenhuma ocorrência informada!" sizeTitle={32} colorTitle="error" />
          </VuiBox>
        </CardInfoLink>
        <Footer />
      </BasicLayout>
    );
  }

  if (isSaveOccurrence) {
    return (
      <BasicLayout>
        <CardInfoLink>
          <VuiBox
            style={{
              padding: 20,
            }}
          >
            <Grid container spacing="18px">
              <Grid item xs={12} lg={6} xl={3} />
              <Grid item xs={12} lg={6} xl={7}>
                <VuiLabel />
                <VuiLabel />
                <Grid container spacing="18px">
                  {/* Linha 1 */}
                  <Grid item xs={12} lg={6} xl={2} />
                  <Grid item xs={12} lg={6} xl={6}>
                    <VuiLabel title="Ocorrência salva!" sizeTitle={32} colorTitle="success" />
                    <VuiLabel />
                    <VuiLabel
                      title={`Suporte${
                        states.occurrenceOperatorName ? " " + states.occurrenceOperatorName : ""
                      }!`}
                      sizeTitle={24}
                    />
                    <VuiLabel />
                    <VuiLabel
                      title={`Obrigado por informar a situação do link da ${states.initials} - ${states.name}`}
                      sizeTitle={24}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6} xl={2} />

                  {/* Linha 7 */}
                  <Grid item xs={12} lg={6} xl={7}></Grid>
                  <Grid item xs={12} lg={6} xl={2}>
                    <VuiLabel />
                    <VuiButton
                      color="success"
                      onClick={() => {
                        closeOccurrence();
                      }}
                      sx={{ height: 36, width: "100%" }}
                    >
                      Fechar
                    </VuiButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6} xl={3} />
            </Grid>
          </VuiBox>
        </CardInfoLink>
        <Footer />
      </BasicLayout>
    );
  }

  return (
    <BasicLayout>
      <CardInfoLink>
        <VuiBox
          style={{
            padding: 20,
          }}
        >
          <Grid container spacing="18px">
            <Grid item xs={12} lg={6} xl={3} />
            <Grid item xs={12} lg={6} xl={7}>
              <VuiLabel />
              <VuiLabel />
              <Grid container spacing="18px">
                {/* Linha 1 */}
                <Grid item xs={12} lg={6} xl={3}>
                  <VuiLabel title="ID Ocorrência:" />
                  <VuiInput disabled={true} value={states.id} />
                </Grid>
                <Grid item xs={12} lg={6} xl={3}>
                  <VuiLabel title="Data Ocorrência:" />
                  <VuiInput
                    disabled={true}
                    value={dayjs(states.createdAt).format("DD/MM/YYYY HH:mm:ss")}
                  />
                </Grid>

                <Grid item xs={12} lg={6} xl={3}>
                  <VuiLabel title="Status da Ocorrência:" />
                  <VuiInput
                    disabled={true}
                    value={`${statusOccurrence[states.status]} ${
                      states.status === 5
                        ? "as " + dayjs(states.updatedAt).format("DD/MM/YYYY HH:mm:ss")
                        : ""
                    }`}
                  />
                </Grid>

                {/* Linha 2 */}
                <Grid item xs={12} lg={6} xl={3} />
                <Grid item xs={12} lg={6} xl={9}>
                  <VuiLabel title="Unidade Prisional/Penitenciária Regional:" />
                  <VuiInput
                    rows={6}
                    multiline
                    disabled={true}
                    value={`${states.initials} - ${states.name}\n\nEndereço:${
                      states.address
                    }\nPonto de referência:${
                      states.referencePoint ? states.referencePoint : ""
                    } \nContato: ${states.contact}\nTelefone: ${states.phone}`}
                  />
                </Grid>

                {/* Linha 3 */}
                <Grid item xs={12} lg={6} xl={3} />
                <Grid item xs={12} lg={6} xl={9}>
                  <VuiBox
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexdirection: "row",
                    }}
                  >
                    <VuiLabel title="Operadora/Link/Circuito:" />
                    <VuiLabel
                      title={isCopied ? "Copiado para área de transferência!" : ""}
                      colorTitle="error"
                    />
                  </VuiBox>
                  <VuiBox
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexdirection: "row",
                    }}
                  >
                    <VuiInput
                      disabled={true}
                      value={`${states.carrier}${
                        states.subCarrier ? "(" + states.subCarrier + ") - " : " - "
                      }(RB/GW: ${states.gateway}) - ${states.circuit}`}
                    />
                    <VuiLabel />
                    <VuiLabel />
                    <VuiBox
                      onClick={handleCopyClick}
                      sx={{
                        display: "inline-flex",
                        alignItems: "center",
                        cursor: "pointer",
                        "& .material-icons-round": {
                          fontSize: "1.125rem",
                          transform: `translate(2px, -0.5px)`,
                          transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
                        },
                        "&:hover .material-icons-round, &:focus .material-icons-round": {
                          transform: `translate(6px, -0.5px)`,
                        },
                      }}
                    >
                      <IoCopyOutline size={32} color={"white"} />
                    </VuiBox>
                  </VuiBox>
                </Grid>

                {/* Linha 4 */}
                <Grid item xs={12} lg={6} xl={3}></Grid>

                <Grid item xs={12} lg={6} xl={3}>
                  <VuiLabel title="Marca da ONU:" />
                  <VuiInput disabled={true} value={states.brandOnu} />
                </Grid>

                <Grid item xs={12} lg={6} xl={3}>
                  <VuiLabel title="Modelo da ONU:" />
                  <VuiInput disabled={true} value={states.modelOnu} />
                </Grid>

                <Grid item xs={12} lg={6} xl={3}>
                  <VuiLabel />
                </Grid>

                {/* Linha 5 */}
                <Grid item xs={12} lg={6} xl={3}></Grid>

                <Grid item xs={12} lg={6} xl={3}>
                  <VuiLabel title="Nome do Operador:" />
                  <VuiInput
                    placeholder="Digite o nome do operador (suporte)"
                    value={states.occurrenceOperatorName}
                    onChange={(e) => {
                      console.log(">>> Titulo", e.target.value);
                      const newStates = {
                        ...states,
                        occurrenceOperatorName: e.target.value,
                      };

                      setStates(newStates);
                    }}
                  />
                </Grid>

                <Grid item xs={12} lg={6} xl={2}>
                  <VuiLabel title="Protocolo:" />
                  <VuiInput
                    placeholder="Digite o número do Protocolo"
                    value={states.occurrenceProtocolNumber}
                    onChange={(e) => {
                      console.log(">>> value", e.target.value.replace(/[^0-9]/g, ""));
                      const newStates = {
                        ...states,
                        occurrenceProtocolNumber: e.target.value.replace(/[^0-9]/g, ""),
                      };

                      setStates(newStates);
                    }}
                  />
                </Grid>

                <Grid item xs={12} lg={6} xl={2}>
                  <VuiLabel title="Chamado Técnico:" />
                  <VuiInput
                    placeholder="Digite o número do Chamado Técnico"
                    value={states.occurrenceCalledTechnician}
                    onChange={(e) => {
                      console.log(">>> Titulo", e.target.value);
                      const newStates = {
                        ...states,
                        occurrenceCalledTechnician: e.target.value.replace(/[^0-9]/g, ""),
                      };

                      setStates(newStates);
                    }}
                  />
                </Grid>

                <Grid item xs={12} lg={6} xl={2}>
                  <VuiLabel title="SLI do Chamado:" />
                  <VuiInput
                    disabled
                    value={SLIOccurrence(
                      moment(states.status === 5 ? states.updatedAt : new Date()).format(
                        "DD/MM/YYYY HH:mm:ss"
                      ),
                      moment(states.createdAt).format("DD/MM/YYYY HH:mm:ss")
                    )}
                  />
                </Grid>

                {/* Linha 6 */}
                <Grid item xs={12} lg={6} xl={3} />

                <Grid item xs={12} lg={6} xl={9}>
                  <VuiLabel title="Motivo do Suporte:" />
                  <VuiInputText
                    rows={4}
                    multiline
                    placeholder="Digite o motivo do Chamado"
                    value={states.occurrenceComments}
                    onChange={(e) => {
                      console.log(">>> Titulo", e.target.value);
                      const newStates = {
                        ...states,
                        occurrenceComments: e.target.value,
                      };

                      setStates(newStates);
                    }}
                  />
                </Grid>

                {/* Linha 7 */}
                <Grid item xs={12} lg={6} xl={7}></Grid>
                <Grid item xs={12} lg={6} xl={2}>
                  <VuiLabel />
                  <VuiButton
                    color="success"
                    disabled={states.status === 0 ? isDisabledButton : false}
                    onClick={() => {
                      if (states.status === 0) {
                        saveOccurrence();
                      } else {
                        closeOccurrence();
                      }
                    }}
                    sx={{ height: 36, width: "100%" }}
                  >
                    {states.status === 0 ? "Salvar" : "Fechar"}
                  </VuiButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6} xl={3} />
          </Grid>
        </VuiBox>
      </CardInfoLink>

      <Footer />
    </BasicLayout>
  );
}

function CheckLogin() {
  const isLogged = checkExpiredToken();
  const location = useLocation();
  return isLogged ? (
    <OccurrenceLink />
  ) : (
    <Redirect
      to={
        "/login?redirect=" +
        location.pathname +
        (location.search !== "" ? `${location.search}` : "")
      }
    />
  );
}

export default CheckLogin;
