import { useState, useEffect } from "react";

import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCardLink";
import MiniLoadingCard from "examples/Cards/Loading";

// React icons
import { IoShieldHalfOutline } from "react-icons/io5";

// Service Axios
import api from "services/api";

function MiniCardUnits() {
  const [refreshInterval, setRefreshInterval] = useState(60000);
  const [loading, setLoading] = useState(true);
  const [countAllPUsUnits, setCountAllPUsUnits] = useState(0);
  const [countAllPRsUnits, setCountAllPRsUnits] = useState(0);
  const [countAllADMUnits, setCountAllADMUnits] = useState(0);
  const [countAllUnits, setCountAllUnits] = useState(0);

  async function loadInfoDashAdmin() {
    setLoading(true);
    const res = await api.get("units");
    const data = res.data;

    setCountAllUnits(res.data.length);
    setCountAllPUsUnits(
      data.filter(
        (x) =>
          x.initials.substring(0, 2).toUpperCase() === "UP" ||
          x.initials.substring(0, 3).toUpperCase() === "RUC"
      ).length
    );
    setCountAllPRsUnits(
      data.filter((x) => x.initials.substring(0, 2).toUpperCase() === "PR").length
    );
    setCountAllADMUnits(
      data.filter(
        (x) =>
          x.initials.substring(0, 2).toUpperCase() !== "PR" &&
          x.initials.substring(0, 2).toUpperCase() !== "UP" &&
          x.initials.substring(0, 3).toUpperCase() !== "RUC"
      ).length
    );

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  useEffect(() => {
    loadInfoDashAdmin();
  }, [false]);

  useEffect(() => {
    if (refreshInterval && refreshInterval > 0) {
      const interval = setInterval(loadInfoDashAdmin, refreshInterval);
      return () => clearInterval(interval);
    }
  }, [refreshInterval]);

  if (loading) {
    return <MiniLoadingCard />;
  } else {
    return (
      <MiniStatisticsCard
        title={{ text: "Sede + UP's/PR's/ADM's" }}
        count={`${countAllUnits} Total `}
        percentage={{
          color: "success",
          text: `(${countAllPUsUnits} UP's / ${countAllPRsUnits} PR's / ${countAllADMUnits} ADM's)`,
        }}
        icon={{
          color: "success",
          component: <IoShieldHalfOutline size="22px" color="white" />,
        }}
        openLinkClick="/units"
      />
    );
  }
}

export default MiniCardUnits;
