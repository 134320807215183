import * as React from "react";
import CircularProgress, { CircularProgressProps } from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function CircularProgressWithLabel({ label, value, ...props }) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {value ? (
            <Typography variant="caption" component="div" color="text.main">{`${Math.round(
              value
            )}%`}</Typography>
          ) : (
            <></>
          )}
          {label && (
            <Typography
              mt={1}
              textAlign={"center"}
              variant="caption"
              component="div"
              color="text.main"
            >
              {label}
            </Typography>
          )}
        </div>
      </Box>
    </Box>
  );
}

export default function VuiCircularWithValueLabel({ ...props }) {
  return <CircularProgressWithLabel {...props} />;
}
