import { experimentalStyled as styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

import { IoEllipsisVerticalSharp } from "react-icons/io5";
import { IoCall } from "react-icons/io5";

import VuiTypography from "components/VuiTypography";
import VuiBox from "components/VuiBox";
import VuiLabel from "components/VuiLabel";

import colors from "assets/theme/base/colors";

const CardInfoBranche = styled(Paper)(({ theme }) => ({
  backgroundColor: "#1A2027",
  color: theme.palette.text.secondary,
  borderRadius: 10,
}));

function InfoItemBranche({ item, index }) {
  return (
    <CardInfoBranche>
      <VuiBox
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 10,
        }}
      >
        <VuiTypography
          style={{ fontSize: 14, fontWeight: "bold", marginLeft: 0, color: colors.text.focus }}
        >
          {item.initials}
        </VuiTypography>
        <VuiTypography
          style={{ fontSize: 12, fontWeight: "bold", marginLeft: 0, color: colors.text.focus }}
        >
          {item.local}
        </VuiTypography>
        <VuiTypography
          style={{ fontSize: 12, fontWeight: "bold", marginLeft: 0, color: colors.text.focus }}
        >
          <IoEllipsisVerticalSharp />
        </VuiTypography>
      </VuiBox>
      <VuiBox
        style={{
          display: "flex",
          flexDirection: "column",
          alignSelf: "flex-start",
          marginLeft: 10,
          marginRight: 10,
        }}
      >
        <VuiBox display="flex" flexDirection="row" alignItems="center">
          <VuiBox
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              // width: "100%",
              // height: 210,
            }}
          >
            <VuiTypography
              style={{
                fontSize: 32,
                fontWeight: "bold",
                color: !item.status ? colors.alert.online : colors.alert.offline,
                marginRight: 20,
                textAlign: "center",
              }}
            >
              <IoCall />
            </VuiTypography>

            <VuiBox
              style={{
                display: "flex",
                flexDirection: "column",
                height: "50%",
              }}
            >
              <VuiLabel
                title="Ramal:"
                subtitle={item.migrated === 1 ? item.id_branch : item.id_branch_old}
              />
              <VuiLabel title="Contato:" subtitle={item.contact ? item.contact : "Não informado"} />
            </VuiBox>
          </VuiBox>
        </VuiBox>
        <VuiBox mb="20px" flexDirection="column">
          <VuiTypography
            color="text"
            style={{
              fontSize: 11,
              fontWeight: "100",
              textAlign: "right",
            }}
          >
            Última Atualização {item.updatedAt}
          </VuiTypography>
        </VuiBox>
      </VuiBox>
    </CardInfoBranche>
  );
}

export default InfoItemBranche;
