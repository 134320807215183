import React from "react";
import { Card, Icon } from "@mui/material";

import VuiOpenLink from "components/VuiOpenLink";

import Links from "./components/DashLinks";

function LinkStatus() {
  return (
    <Card sx={{ backgroundColor: "green" }}>
      <Links />
    </Card>
  );
}

export default LinkStatus;
