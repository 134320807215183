import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
// import { experimentalStyled as styled } from "@mui/material/styles";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";
import VuiInput from "components/VuiInputText";
import api from "services/api";

import moment from "moment";

// Branches page components
import ItemBranches from "./ItemBranches";

function Branches() {
  const [searchBranche, setSearchBranche] = useState("");
  const [branches, setBranches] = useState([]);
  const [branchesPreferences, setBranchesPreferences] = useState([]);
  const [titleButton, setTitleButton] = useState("Todos os Ramais VoIP's");
  const [titleTable, setTitleTable] = useState("Preferência");

  useEffect(() => {
    async function loadBranchesPreference() {
      const res = await api.get("branches/likes");
      const data = res.data.map((x) => ({
        ...x,
        status: 0,
        updatedAt: moment().format("DD/MM/YYYY HH:mm:ss"),
      }));
      setBranches(data);
      setBranchesPreferences(data);
    }
    loadBranchesPreference();
  }, [false]);

  return (
    <Card id="delete-account" sx={{ height: "100%" }}>
      {/* Header */}
      <VuiBox mb="28px" display="flex" justifyContent="space-between" alignItems="center">
        <VuiTypography variant="h6" fontWeight="medium" color="white">
          {titleTable}
        </VuiTypography>
        <VuiTypography variant="h6" fontWeight="medium" color="white">
          <VuiInput
            value={searchBranche}
            onChange={async (e) => {
              const strSearch = e.target.value;
              setSearchBranche(strSearch);
              const res = await api.post("branches/initials", { initials: strSearch });
              if (res.data.length > 0) {
                const data = res.data.map((x) => ({
                  ...x,
                  status: 0,
                  updatedAt: moment().format("DD/MM/YYYY HH:mm:ss"),
                }));
                // console.log(data);
                setBranches(data);
              } else {
                setBranches(branchesPreferences);
              }
            }}
            placeholder="Procurar SEDE ou Unidade"
            icon={{ component: "search", direction: "left" }}
            cancelInput
            onClickCancelInput={() => {
              setSearchBranche("");
              setBranches(branchesPreferences);
            }}
            sx={({ breakpoints }) => ({
              [breakpoints.down("sm")]: {
                maxWidth: "80px",
              },
              [breakpoints.only("sm")]: {
                maxWidth: "80px",
              },
              backgroundColor: "alert.main !important",
            })}
          />
        </VuiTypography>
        <VuiButton
          variant="contained"
          color="info"
          size="small"
          onClick={async () => {
            if (titleButton === "Todos os Ramais VoIP's") {
              const res = await api.get("branches");
              setBranches(res.data);
              setTitleButton("Preferência");
              setTitleTable("Todos os Ramais");
            } else {
              setBranches(branchesPreferences);
              setTitleButton("Todos os Ramais VoIP's");
              setTitleTable("Preferência");
            }
          }}
        >
          {titleButton}
        </VuiButton>
      </VuiBox>
      {/* Body */}
      {/* <VuiBox>
        <VuiBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {branches.map((item) => (
            <ItemBranche
              branch={"Ramal: " + item.id_branch}
              unit={item.initials}
              local={item.local}
              contact={item.initials}
              office=""
            />
          ))}
        </VuiBox>
      </VuiBox> */}
      <Box sx={{ flexGrow: 2 }}>
        <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 3, sm: 6, md: 24 }}>
          {branches.map((item, index) => {
            return (
              <Grid item xs={6} sm={6} md={6} key={index}>
                <ItemBranches item={item} index={index} />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Card>
  );
}

export default Branches;
