import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for VuiInput
import VuiInputRoot from "./components/VuiInputRoot";
import VuiInputWithIconRoot from "./components/VuiInputWithIconRoot";
import VuiInputIconBoxRoot from "./components/VuiInputIconBoxRoot";
import VuiInputIconRoot from "./components/VuiInputIconRoot";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";

// Vision UI Dashboard React contexts
import { useVisionUIController } from "context";

// Icon Cancel
import { IoCloseOutline } from "react-icons/io5";

const VuiInputText = forwardRef(
  ({ size, icon, cancelInput, onClickCancelInput, error, success, disabled, ...rest }, ref) => {
    let template;
    const [controller] = useVisionUIController();
    const { direction } = controller;
    const iconDirection = icon.direction;

    if (icon.component && icon.direction === "left") {
      template = (
        <VuiInputWithIconRoot ref={ref} ownerState={{ error, success, disabled }}>
          <VuiInputIconBoxRoot ownerState={{ size }}>
            <VuiInputIconRoot fontSize="small" ownerState={{ size }}>
              {icon.component}
            </VuiInputIconRoot>
          </VuiInputIconBoxRoot>
          <VuiInputRoot
            {...rest}
            ownerState={{ size, error, success, iconDirection, direction, disabled }}
          />
          {cancelInput ? (
            <VuiButton
              onClick={() => onClickCancelInput()}
              iconOnly
              variant="text"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginLeft: 0,
                marginRight: 0,
              }}
            >
              <IoCloseOutline fontSize="small" />
            </VuiButton>
          ) : null}
        </VuiInputWithIconRoot>
      );
    } else if (icon.component && icon.direction === "right") {
      template = (
        <VuiInputWithIconRoot ref={ref} ownerState={{ error, success, disabled }}>
          <VuiInputRoot
            {...rest}
            ownerState={{ size, error, success, iconDirection, direction, disabled }}
          />
          <VuiInputIconBoxRoot ownerState={{ size }}>
            <VuiInputIconRoot fontSize="small" ownerState={{ size }}>
              {icon.component}
            </VuiInputIconRoot>
          </VuiInputIconBoxRoot>
        </VuiInputWithIconRoot>
      );
    } else {
      template = (
        <VuiInputRoot {...rest} ref={ref} ownerState={{ size, error, success, disabled }} />
      );
    }

    return template;
  }
);

// Setting default values for the props of VuiInput
VuiInputText.defaultProps = {
  size: "medium",
  icon: {
    component: false,
    direction: "none",
  },
  cancelInput: false,
  onClickCancelInput: null,
  error: false,
  success: false,
  disabled: false,
};

// Typechecking props for the VuiInput
VuiInputText.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  icon: PropTypes.shape({
    component: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
    direction: PropTypes.oneOf(["none", "left", "right"]),
  }),
  onClickCancelInput: PropTypes.func,
  cancelInput: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default VuiInputText;
