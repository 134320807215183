import VuiBox from "components/VuiBox";

import Loading from "examples/Icons/Loading";

function VuiLoading({ size = "normal", color = "while" }) {
  const sizeIcon = size === "normal" ? 100 : size === "small" ? 50 : size === "large" ? 150 : 50;
  return (
    <VuiBox
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 500,
      }}
    >
      <Loading size={`${sizeIcon}px`} />
    </VuiBox>
  );
}

export default VuiLoading;
