import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { Html5QrcodeScanner } from "html5-qrcode";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiLabel from "components/VuiLabel";
import VuiInput from "components/VuiInput";
import VuiInputText from "components/VuiInputText";
import VuiSelect from "components/VuiSelect";
import VuiButtonCustom from "components/VuiButtonCustom";

import Footer from "examples/Footer";

// Images
import Loading from "examples/Icons/Loading";

import useWindowDimensions from "hooks/useWindowDimensions";
import breakpoints from "assets/theme/base/breakpoints";

import colors from "assets/theme/base/colors";

import api from "services/api";

import { Container, Content } from "./styles";

const idServiceOrder = uuidv4().split("-");

function VuiRow() {
  return <div style={{ height: 30 }}></div>;
}

function VuiContainer({ children }) {
  return <div style={{ marginTop: 10, width: "100%" }}>{children}</div>;
}

function LoadingServiceOrder() {
  const { height, width } = useWindowDimensions();
  return (
    <VuiBox sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Loading />
    </VuiBox>
  );
}

function FormServiceOrder({ isMobile }) {
  const userLogged = JSON.parse(localStorage.getItem("user"));

  const [loading, setLoading] = useState(true);
  const [lstTypeService, setLstTypeService] = useState([
    {
      label: "Suporte de Computador",
      value: "SC",
    },
    {
      label: "Suporte de Impressora",
      value: "SI",
    },
    {
      label: "Suporte de Scanner",
      value: "SS",
    },
    {
      label: "Suporte de Rede",
      value: "SR",
    },
    {
      label: "Suporte á Pasta Compartilhada",
      value: "SP",
    },
    {
      label: "Suporte de VoIP/Ramal",
      value: "ST",
    },
    {
      label: "Suporte EAD",
      value: "SE",
    },
    {
      label: "Novo Usuário VPN",
      value: "NV",
    },
    {
      label: "Novo E-mail Institucional",
      value: "CE",
    },
    {
      label: "Alterar Senha de E-mail",
      value: "AE",
    },
    {
      label: "Novo de Usuário de Domínio",
      value: "NU",
    },
    {
      label: "Trasferência de Usuário de Domínio",
      value: "TU",
    },
    {
      label: "Exclusão de Usuário de Domínio",
      value: "EU",
    },
    {
      label: "Alterar Senha de Usuário de Domínio",
      value: "AU",
    },
  ]);
  const [departmentSelected, setDepartmentSelected] = useState("");
  const [typeServiceSelect, setTypeServiceSelect] = useState(null);

  const [states, setStates] = useState({
    idOrderService: idServiceOrder[0] + "" + idServiceOrder[1] + "" + idServiceOrder[2],
    dtOrderService: moment().format("DD/MM/YYYY HH:mm"),
  });

  const [scanResult, setScanResult] = useState(null);

  const idOrderService = idServiceOrder[0] + "" + idServiceOrder[1] + "" + idServiceOrder[2];
  // [0].replace(/\-/g, "")
  // const idOrderService = uuidv4();

  useEffect(() => {
    setLoading(true);
    async function loadUnits() {
      const res = await api.get(
        `departments/s?acronym=${userLogged.departmentAcronym}&fields=initials,department,acronym`
      );
      userLogged.department = "";
      if (res.data.data.length === 1) {
        console.log(
          ">>> departments=",
          res.data.data[0].acronym + " - " + res.data.data[0].department
        );
        setDepartmentSelected(res.data.data[0].acronym + " - " + res.data.data[0].department);
      }
      setLoading(false);
    }
    loadUnits();

    const scanner = new Html5QrcodeScanner("reader", {
      qrbox: { width: 250, height: 250 },
      fps: 5,
    });

    function successReader(result) {
      scanner.clear();
      setScanResult(result);
    }

    function errorReader(err) {
      console.warn(err);
    }

    scanner.render(successReader, errorReader);

    // console.log(">>> userLogged", userLogged, isMobile);
  }, [false]);

  if (isMobile) {
    return (
      <>
        <VuiContainer>
          <VuiLabel title="Abertura de Chamado de Suporte" sizeTitle={18} />
        </VuiContainer>
        <VuiRow />

        <div id="reader"></div>

        {/* <VuiContainer>
          <Scanner
            onDecode={(result) => setData(result)}
            onError={(error) => setData(`error: ${error?.message}`)}
          />
          {data}
        </VuiContainer> 
        
        <VuiRow />
        */}

        <VuiContainer>
          <VuiLabel title="Número da Ordem" />
          <VuiInput value={states.idOrderService} disabled />
        </VuiContainer>
        <VuiContainer>
          <VuiLabel title="Data de Abertura" />
          <VuiInput value={states.dtOrderService} disabled />
        </VuiContainer>
        <VuiContainer>
          <VuiLabel title="Requerente do Chamado" />
          <VuiInput placeholder="Informe a Contato/Responsável" value={userLogged.name} disabled />
        </VuiContainer>
        <VuiContainer>
          <VuiLabel title="Departamento" />
          <VuiInput value={departmentSelected} disabled />
        </VuiContainer>
        <VuiContainer>
          <VuiSelect
            label="Tipo de Manutenção"
            name={"selectTypeMaintenance"}
            placeholder={isMobile ? "Escolha a Manutenção?" : "Informe o Tipo de Manutenção?"}
            options={lstTypeService}
            onCustomChange={async (e) => {
              console.log(">>> lstTypeService:", e);
              setTypeServiceSelect(e.value);
            }}
            // defaultInputValue={userLogged?.unit}
          />
        </VuiContainer>

        {typeServiceSelect === "NU" && (
          <VuiContainer>
            <Grid item xs={12} lg={12} xl={12} />
            <Grid item xs={12} lg={12} xl={3}>
              <VuiLabel title="Nome Completo" />
              <VuiInput value={""} />
            </Grid>
            <Grid item xs={12} lg={12} xl={3}>
              <VuiLabel title="CPF" />
              <VuiInput value={""} />
            </Grid>
            <Grid item xs={12} lg={12} xl={3}>
              <VuiLabel title="Matricula" />
              <VuiInput value={""} />
            </Grid>
            <Grid item xs={12} lg={12} xl={3}>
              <VuiLabel title="E-mail Institucional" />
              <VuiInput value={""} />
            </Grid>
          </VuiContainer>
        )}

        {typeServiceSelect && typeServiceSelect !== "NU" && (
          <>
            <VuiContainer>
              <VuiLabel title="Descrição do Problema:" />
              <VuiInputText
                rows={8}
                multiline
                placeholder="Descreva aqui com todos os detalhes do problema"
                style={{ marginTop: 1 }}
              />
            </VuiContainer>
          </>
        )}
      </>
    );
  } else {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12} lg={12} xl={12}>
          <VuiLabel title="Abertura de Chamado de Suporte" sizeTitle={32} />
          <VuiRow />
          <div id="reader"></div>

          <Grid container spacing={1}>
            {/*  */}
            <Grid item xs={12} lg={12} xl={4}>
              <VuiLabel title="Número da Ordem" />
              <VuiInput value={idOrderService} disabled />
            </Grid>
            <Grid item xs={12} lg={12} xl={4}>
              <VuiLabel title="Data de Abertura" />
              <VuiInput value={moment().format("DD/MM/YYYY HH:mm")} disabled />
            </Grid>
            <Grid item xs={12} lg={12} xl={4} />

            {/*  */}
            <Grid item xs={12} lg={12} xl={4}>
              <VuiLabel title="Requerente do Chamado" />
              <VuiInput
                placeholder="Informe a Contato/Responsável"
                value={userLogged.name}
                disabled
              />
            </Grid>

            <Grid item xs={12} lg={12} xl={4}>
              <VuiLabel title="Departamento" />
              <VuiInput value={departmentSelected} disabled />
            </Grid>

            {/*  */}
            <Grid item xs={12} lg={12} xl={4}>
              <div style={{ width: isMobile ? "180%" : "100%" }}>
                <VuiSelect
                  label="Tipo de Manutenção"
                  name={"selectTypeMaintenance"}
                  placeholder={isMobile ? "Escolha a Manutenção" : "Informe o Tipo de Manutenção"}
                  options={lstTypeService}
                  onCustomChange={async (e) => {
                    console.log(">>> lstTypeService:", e);
                    setTypeServiceSelect(e.value);
                  }}
                  // defaultInputValue={userLogged?.unit}
                />
              </div>
            </Grid>
            {/* <Grid item xs={12} lg={12} xl={6} /> */}

            {/*  */}
            {typeServiceSelect === "NU" && (
              <>
                <Grid item xs={12} lg={12} xl={12} />
                <Grid item xs={12} lg={12} xl={3}>
                  <VuiLabel title="Nome Completo" />
                  <VuiInput value={""} />
                </Grid>
                <Grid item xs={12} lg={12} xl={3}>
                  <VuiLabel title="CPF" />
                  <VuiInput value={""} />
                </Grid>
                <Grid item xs={12} lg={12} xl={3}>
                  <VuiLabel title="Matricula" />
                  <VuiInput value={""} />
                </Grid>
                <Grid item xs={12} lg={12} xl={3}>
                  <VuiLabel title="E-mail Institucional" />
                  <VuiInput value={""} />
                </Grid>
              </>
            )}

            {typeServiceSelect !== "NU" && (
              <Grid item xs={12} lg={12} xl={12}>
                <>
                  <VuiLabel title="Descrição do Problema:" />
                  <VuiInputText
                    rows={8}
                    multiline
                    placeholder="Descreva aqui com todos os detalhes do problema"
                    style={{ marginTop: 1 }}
                  />
                </>
              </Grid>
            )}

            {/*  */}
            <Grid item xs={12} lg={12} xl={10}></Grid>
            <Grid item xs={12} lg={12} xl={2}>
              <VuiButtonCustom
                title="Salvar"
                color="success"
                fontWeight="bold"
                onClick={() => {}}
                // disabled
                // disabled={isDisabledSave}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

function ServiceOrder() {
  // const { columns, rows } = data();
  const { search } = useLocation();
  const { height, width } = useWindowDimensions();

  const actionCommand = search.replace("?", "").split("=")[0];
  const actionExec = search.replace("?", "").split("=")[1];

  const [loading, setLoading] = useState(true);
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener("resize", displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [false]);

  return (
    <Container>
      <Content width={isMobileView ? 90 : 60} p={isMobileView ? 0.5 : 2.25}>
        {loading ? <LoadingServiceOrder /> : <FormServiceOrder isMobile={isMobileView} />}
      </Content>
      <Footer />
    </Container>
  );
}

export default ServiceOrder;
