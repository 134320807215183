import { useState, useEffect } from "react";

import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import MiniLoadingCard from "examples/Cards/Loading";

// React icons
import { IoLinkSharp } from "react-icons/io5";

// Service Axios
import api from "services/api";

function MiniCardLinks() {
  const [refreshInterval, setRefreshInterval] = useState(60000);
  const [loading, setLoading] = useState(true);
  const [countLinksOffLines, setCountLinksOfflines] = useState(0);
  const [countLinksOnLines, setCountLinksOnlines] = useState(0);
  const [countAllLinks, setCountAllLinks] = useState(0);

  async function loadInfoDashAdmin() {
    setLoading(true);
    const data = [];
    const res = await api.get("links");

    res.data.map((x) => {
      if (x.enabled) {
        data.push({ status: x.laststatus });
      }
    });

    const linksTotal = data.length;
    const linksOffline = data.filter((x) => !x.status).length;
    const linksOnline = (linksOffline / linksTotal) * 100;

    setCountAllLinks(linksTotal);
    setCountLinksOfflines(linksOffline);
    setCountLinksOnlines(linksOnline.toFixed(2));

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  useEffect(() => {
    loadInfoDashAdmin();
  }, [false]);

  useEffect(() => {
    if (refreshInterval && refreshInterval > 0) {
      const interval = setInterval(loadInfoDashAdmin, refreshInterval);
      return () => clearInterval(interval);
    }
  }, [refreshInterval]);

  if (loading) {
    return <MiniLoadingCard />;
  } else {
    return (
      <MiniStatisticsCard
        title={{ text: "Links", fontWeight: "regular" }}
        count={countAllLinks - countLinksOffLines}
        percentage={{
          color: countLinksOffLines > 0 ? "error" : "success",
          text: countLinksOffLines > 0 ? `${countLinksOnLines}% Online` : `100% Online`,
        }}
        icon={{
          color: countLinksOffLines > 0 ? "error" : "success",
          component: <IoLinkSharp size="22px" color="white" />,
        }}
      />
    );
  }
}

export default MiniCardLinks;
