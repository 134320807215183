import { useState, useEffect, useMemo } from "react";

// react-router components
import { Route, Switch, Redirect, useLocation } from "react-router-dom";

import { useLoadScript } from "@react-google-maps/api";

import moment from "moment";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";

import api from "services/api";

// Vision UI Dashboard React example components
import Sidenav from "examples/Sidenav";

// Vision UI Dashboard React themes
import theme from "assets/theme";

// Vision UI Dashboard React routes
import routes from "routes";

// Vision UI Dashboard React contexts
import {
  useVisionUIController,
  setMiniSidenav,
  setOpenConfigurator,
  setIsLoggedContext,
} from "context";

const KEY_MAPS = "AIzaSyA5y0onE92_1-LJVseKteBV9dzlUYKd4yc";

async function checkTokenIsValid(token, cb) {
  const res = await api.get("jwt/checkToken", {
    headers: {
      authorization: token,
    },
  });
  const tokenItens = res.data.data ? res.data.data : { validToken: false };
  cb(tokenItens);
}

export default function App() {
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  const [isLogged, setIsLogged] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const dateNow = new Date();
    const nowTimeStamp = dateNow.getTime();
    const isLoggedStorage = localStorage.getItem("logged");
    const timeStampStorage = parseInt(localStorage.getItem("timeStampOut"));

    // console.log(window.location.host.split(":")[0]);

    if (isLoggedStorage && nowTimeStamp <= timeStampStorage) {
      setIsLogged(isLoggedStorage);
      setLoading(true);
    } else {
      const token = localStorage.getItem("token");
      if (token) {
        checkTokenIsValid(token, (checkToken) => {
          setIsLogged(checkToken.validToken);
          setIsLoggedContext(dispatch, checkToken.validToken);
        });
      }
      setLoading(true);
    }
  }, [isLogged, loading]);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const { isLoadedMaps } = useLoadScript({
    googleMapsApiKey: KEY_MAPS,
  });

  const getRoutes = (allRoutes, isLogged) =>
    allRoutes.map((route) => {
      if (route.key === "maps" && isLoadedMaps) {
        return null;
      }

      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} component={route.component} key={route.key} />;
      }
      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <Sidenav
          color={sidenavColor}
          brand=""
          brandName="SEAP"
          routes={routes}
          isLogged={isLogged}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        />
      )}
      <Switch>
        {getRoutes(routes, isLogged)}
        <Redirect from="*" to="/dashboard" />
      </Switch>
    </ThemeProvider>
  );
}
