import { useState, useEffect } from "react";

import { experimentalStyled as styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

import Grid from "@mui/material/Grid";

import { IoEllipsisVerticalSharp } from "react-icons/io5";

import { BarChart, Bar } from "recharts";

import moment from "moment";

import VuiTypography from "components/VuiTypography";
import VuiBox from "components/VuiBox";
import VuiLabel from "components/VuiLabel";
import VuiProgress from "components/VuiProgress";

import breakpoints from "assets/theme/base/breakpoints";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".");
}

function formatReal(x) {
  return x.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
}

function formatPhoneBR(x) {
  if (x) {
    return x.toString().replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, "($1) $2 $3-$4");
  }
  return x;
}

function nothingInfo(x) {
  if (x) {
    return x;
  }
  return "";
}

function colorAlertGraphics(alertToner) {
  if (alertToner >= 20 && alertToner <= 30) return "alert";
  if (alertToner >= 1 && alertToner <= 20) return "error";
  return "success";
}

const CardInfoPrinter = styled(Paper)(({ theme }) => ({
  backgroundColor: "#1A2027",
  color: theme.palette.text.secondary,
  borderRadius: 10,
}));

function VuiInfoItemUnit({ item, isGroupAdmin, index }) {
  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
        setMobileNavbar(false);
      } else {
        setMobileView(false);
        setMobileNavbar(false);
      }
    }
    window.addEventListener("resize", displayMobileNavbar);
    displayMobileNavbar();
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  if (item) {
    return (
      <CardInfoPrinter>
        {/* Title Card */}
        <VuiBox
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 10,
          }}
        >
          <VuiTypography color="white" variant="button" fontWeight="bold">
            {item.initials}
          </VuiTypography>
          <VuiTypography
            color="white"
            variant="button"
            fontWeight="regular"
            noWrap={true}
            ml="10px"
            mr="10px"
          >
            {item.name}
          </VuiTypography>
          <VuiTypography color="white" variant="lg">
            <IoEllipsisVerticalSharp />
          </VuiTypography>
        </VuiBox>

        {/* Body Card */}
        {/* sijTj&uV */}
        <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 3, sm: 2, md: 2 }} p={"20px"}>
          <VuiBox width="100%">
            <VuiLabel title="Localização" sizeTitle={14} fontWeightTitle="bold" />
            <VuiLabel title="Endereço:" subtitle={item.address} fontWeightSubTitle="bold" />
            <VuiLabel
              title="Ponto de Referência:"
              subtitle={item.referencePoint ? item.referencePoint : "sem informação"}
              fontWeightSubTitle="bold"
            />
            {item.lat && item.lng ? (
              <VuiLabel
                title="Cidade:"
                subtitle={item.city}
                fontWeightSubTitle="bold"
                openLinkClick={`https://www.google.com/maps/dir/${item.lat},${item.lng}/@${item.lat},${item.lng},17z?entry=ttu`}
                iconLinkClick="location_on"
              />
            ) : (
              <VuiLabel title="Cidade:" subtitle={item.city} fontWeightSubTitle="bold" />
            )}
            {item.geDirector && item.adDirector && item.seDirector ? (
              <>
                <VuiLabel />
                <VuiLabel title="Contatos" sizeTitle={14} fontWeightTitle="bold" />
                <Grid container spacing="18px">
                  {/* Linha 1 */}
                  {!mobileView && (
                    <>
                      <Grid item xs={12} lg={6} xl={4}>
                        <VuiLabel title="Diretor Geral:" />
                        <VuiProgress value={0} label={false} />
                      </Grid>
                      <Grid item xs={12} lg={6} xl={4}>
                        <VuiLabel title="Diretor Administrativo:" />
                        <VuiProgress value={0} label={false} />
                      </Grid>
                      {item.seDirector !== item.adDirector ? (
                        <Grid item xs={12} lg={6} xl={4}>
                          <VuiLabel title="Diretor de Segurança:" />
                          <VuiProgress value={0} label={false} />
                        </Grid>
                      ) : (
                        <Grid item xs={12} lg={6} xl={4} />
                      )}
                    </>
                  )}
                  {/* Linha 2 */}
                  <Grid item xs={12} lg={6} xl={4}>
                    {mobileView && <VuiLabel title="Diretor Geral:" fontWeightTitle="bold" />}
                    <VuiLabel
                      title={nothingInfo(item.geDirector)}
                      noWrapTitle
                      fontWeightTitle="bold"
                    />
                    <VuiLabel
                      title="Celular:"
                      subtitle={`${nothingInfo(formatPhoneBR(item.phoneAdDirector))}${
                        item.numberPhoneGeCoorp === 1 ? "*" : ""
                      }`}
                      sizeSubTitle={12}
                      noWrapSubTitle
                    />

                    <VuiLabel
                      title="VoIP:"
                      subtitle={nothingInfo(item.extensionGeDirector)}
                      sizeSubTitle={12}
                      noWrapSubTitle
                    />
                    <VuiLabel title={nothingInfo(item.emailGeDirector)} noWrapTitle />
                    <VuiLabel />
                  </Grid>
                  <Grid item xs={12} lg={6} xl={4}>
                    {mobileView && (
                      <VuiLabel title="Diretor Administrativo:" fontWeightTitle="bold" />
                    )}
                    <VuiLabel title={nothingInfo(item.adDirector)} noWrapTitle />
                    <VuiLabel
                      title="Celular:"
                      subtitle={`${nothingInfo(formatPhoneBR(item.phoneAdDirector))}${
                        item.numberPhoneAdCoorp === 1 ? "*" : ""
                      }`}
                      sizeSubTitle={12}
                      noWrapSubTitle
                    />
                    <VuiLabel
                      title="VoIP:"
                      subtitle={nothingInfo(item.extensionAdDirector)}
                      sizeSubTitle={12}
                      noWrapSubTitle
                    />
                    <VuiLabel title={nothingInfo(item.emailAdDirector)} noWrapTitle />
                    <VuiLabel />
                  </Grid>
                  {item.seDirector !== item.adDirector ? (
                    <Grid item xs={12} lg={6} xl={4}>
                      {mobileView && (
                        <VuiLabel title="Diretor de Segurança:" fontWeightTitle="bold" />
                      )}
                      <VuiLabel title={nothingInfo(item.seDirector)} noWrapTitle />
                      <VuiLabel
                        title="Celular:"
                        subtitle={`${nothingInfo(formatPhoneBR(item.phoneSeDirector))}${
                          item.numberPhoneSeCoorp === 1 ? "*" : ""
                        }`}
                        sizeSubTitle={12}
                        noWrapSubTitle
                      />
                      <VuiLabel
                        title="VoIP:"
                        subtitle={nothingInfo(item.extensionSeDirector)}
                        sizeSubTitle={12}
                        noWrapSubTitle
                      />
                      <VuiLabel title={nothingInfo(item.emailSeDirector)} noWrapTitle />
                      <VuiLabel />
                    </Grid>
                  ) : (
                    <Grid item xs={12} lg={6} xl={4} />
                  )}
                </Grid>
              </>
            ) : (
              <VuiBox sx={{ height: 188 }} />
            )}
          </VuiBox>
        </Grid>

        <VuiBox display="flex" justifyContent="flex-end" alignItems="center" p="10px">
          <VuiBox>
            <VuiLabel
              title={`Última Atualização ${item.lastUpdatedAt}`}
              sizeTitle={11}
              fontWeightTitle="bold"
            />
          </VuiBox>
        </VuiBox>
      </CardInfoPrinter>
    );
  } else {
    return <CardInfoPrinter />;
  }
}

export default VuiInfoItemUnit;
